import React, { useState, memo } from 'react';
import { Typography, Paper, Box, useTheme, IconButton, Tooltip, Checkbox } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store';
import { saveMovieToPlaylistSlice } from '../../../../features/playlistSlice';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { createMonitoringUserUsageOpenSummary, createMonitoringAddToPlaylistUsage } from '../../../../api/serverApis/monitoringApi';
import { setCurrentVideo } from '../../../../features/quizSlice';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DescriptionIcon from '@mui/icons-material/Description';
interface SubObjectComponentProps {
  index: number;
  mainVideo: any;
  subVideo: any;
  handleSubVideoSelect: (subVideo: any, movieId: string, subVideoId: string, e: any) => void;
  formatTimeOrPages: (subVideo: any) => string;
  finishedSubVideos: { [key: string]: string[] };
  handleVideoSelect: (subVideo: any) => void;
  handleQuizButtonClick: (quizData: any, movieId: string, id: string, e: any) => void;
  onExpandToggle: (index: number, isExpanded: boolean) => void;
  isExpanded: boolean;
  selectedSubVideoId: boolean;
  handleSubVideoClick: (subVideoId: string) => void;
  courseId: string;
  videoData: any;
  isThereAQuiz: (subVideo: any) => boolean;
  selectedQuizSubVideoId: boolean;
}

const SubObjectComponent: React.FC<SubObjectComponentProps> = memo(({
  index,
  finishedSubVideos,
  mainVideo,
  subVideo,
  handleSubVideoSelect,
  formatTimeOrPages,
  handleVideoSelect,
  handleQuizButtonClick,
  onExpandToggle,
  isExpanded,
  selectedSubVideoId,
  handleSubVideoClick,
  courseId,
  videoData,
  isThereAQuiz,
  selectedQuizSubVideoId,
}) => {
  const theme = useTheme();
  const borderColor = theme.palette.mode === 'dark' ? 'white' : 'black';
  const [expanded, setExpanded] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(finishedSubVideos[mainVideo.movieId]?.includes(subVideo.id) || false);
  const dispatch = useDispatch<AppDispatch>();
  const currentVideo = useSelector((state: RootState) => state.quiz.currentVideo);
  const permissions = JSON.parse(localStorage.getItem('permissions') || '{}');
  const canAccessQuiz = permissions?.TableOfContent?.seeQuiz?.canAccess;

  const handleAddToPlaylist = (subVideo: any) => {
    const data = {
      _id: mainVideo._id,
      articleId: mainVideo.movieId,
      audioFileName: mainVideo.subject,
      audioLength: subVideo.length,
      courseId: courseId,
      description: mainVideo.subSubject,
      endTime: subVideo.endTime,
      mainCategory: mainVideo.subject,
      movieLink: mainVideo.url,
      // pageImage:pageImage,
      // pageLink: pageLink,
      score: 'from TOC',
      pageNumber: subVideo.startPageNumber,
      pdfImageLocation: subVideo.pdfImageLocation,
      pdfTableText: subVideo.pdfTableText,
      pdfText: subVideo.pdfText,
      startTime: videoData.type === 'pdf' ? subVideo.startPageNumber : subVideo.startTime,
      summaryText: subVideo.summary,
      title: subVideo.subject,
      type: videoData.type,
    };
    dispatch(saveMovieToPlaylistSlice({ playlistData: data }));
    createMonitoringAddToPlaylistUsage(courseId, mainVideo.movieId, mainVideo.subject, subVideo.startTime, subVideo.endTime, subVideo.startPageNumber, videoData.type, subVideo.subject);
  };

  const isCurrentVideo =
    currentVideo?.movieId === mainVideo.movieId &&
    currentVideo?.subVideoId === subVideo.id;

  const toggleExpand = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent triggering the parent click event
    setExpanded(!expanded);
    onExpandToggle(index, !isExpanded);
    if (!isExpanded) {
      createMonitoringUserUsageOpenSummary(mainVideo.movieId, subVideo.id);
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation(); // Prevent triggering the parent click event
    setIsChecked(e.target.checked);
    // Here you can handle the action when the checkbox is clicked, such as saving the state
  };

  const isHebrew = (text: string) => /[\u0590-\u05FF\uFB1D-\uFB4F\uFB50-\uFDFF]/.test(text);

  const formatTimeOrPagesInternal = (subVideo: any) => {
    if (subVideo.startTime && subVideo.endTime) {
      const calcMin = parseInt(subVideo.endTime) - parseInt(subVideo.startTime);
      const math = Math.ceil(calcMin / 60);
      const min = math <= 9 ? `0${math}` : math.toString();

      if (isHebrew(subVideo.summary)) {
        return <span style={{}}><AccessTimeIcon sx={{ fontSize: '13px', marginRight: '1px', marginLeft: '1px', marginBottom: '-2px' }} />{min} דק'</span>;
      }
      return <span style={{}}><AccessTimeIcon sx={{ fontSize: '13px', marginRight: '1px', marginLeft: '1px', marginBottom: '-2px' }} />{min}min</span>;
    } else if (subVideo.startPageNumber && subVideo.endPageNumber) {
      const startToEnd = `${subVideo.startPageNumber} - ${subVideo.endPageNumber}`;
      const numberOfPages = subVideo.endPageNumber - subVideo.startPageNumber;

      return (
        <span style={{ display: 'flex', marginRight: '-25px', }}>
          {/* Page range with icons */}
          <span>{subVideo.startPageNumber}</span>
          <ArrowRightAltIcon sx={{ fontSize: '15px' }} />
          <span>{subVideo.endPageNumber}</span>

          <span style={{ marginLeft: '5px' }}></span>

          {/* Total number of pages with icons */}
          <DescriptionIcon sx={{ fontSize: '15px' }} />
          <span>{numberOfPages + 1}</span>
        </span>
      );
    }
    return null; // Return null if no conditions are met
  };

  return (
    <Paper
      key={`${mainVideo.movieId}-${subVideo.id}`}
      sx={{
        width: '95%',
        p: 1, // Reduced padding
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        my: 0.1, // Reduced vertical margin

        backgroundColor: selectedSubVideoId ? 'rgba(0, 123, 255, 0.1)' : isCurrentVideo ? 'rgba(123, 255, 0, 0.1)' : 'transparent',
        ':hover': { backgroundColor: 'rgba(0, 123, 255, 0.1)' },
        // border: '1px solid',
        //borderColor: borderColor,
        marginLeft: '7px',
        direction: isHebrew(mainVideo.subject) ? 'rtl' : 'ltr'
      }}
      onClick={(e) => {
        e.stopPropagation();
        handleSubVideoSelect(subVideo, mainVideo.movieId, subVideo.id, e);
        handleSubVideoClick(subVideo.id);
        dispatch(setCurrentVideo({ movieId: mainVideo.movieId, subVideoId: subVideo.id }));
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 0, direction: isHebrew(mainVideo.subject) ? 'rtl' : 'ltr' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', direction: isHebrew(mainVideo.subject) ? 'rtl' : 'ltr' }}>

          <Checkbox
            checked={isChecked}
            onClick={(e) => e.stopPropagation()}
            onChange={handleCheckboxChange}
            icon={<Box sx={{ width: 20, height: 20, borderRadius: '4px', border: '1px solid gray' }} />}
            checkedIcon={<Box sx={{ width: 20, height: 20, borderRadius: '4px', border: '1px solid gray', backgroundColor: 'gray' }} ><CheckIcon sx={{ fontSize: '23px', color: 'white', marginLeft: '-2px', marginTop: '-2px' }} /></Box>}
            sx={{ padding: 0, marginRight: isHebrew(mainVideo.subject) ? '0px' : '16px', marginLeft: isHebrew(mainVideo.subject) ? '16px' : '0px' }}
          />
          <Typography sx={{ fontSize: '14px', fontWeight: 'bold', marginRight: 0.5 }}>{index + 1}.</Typography>
          <Box>
            <Tooltip title={subVideo.subject} placement="top">
              <Box
                sx={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2, // Allows 1.5 rows visually
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    lineHeight: '1.2', // Adjust line height to visually match 1.5 rows
                    whiteSpace: 'normal',
                  }}
                  variant="body1"
                >
                  {subVideo.subject}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', direction: isHebrew(subVideo.subSubject) ? 'ltr' : '' }}>
          {/* <Tooltip title="Play Video">
            <IconButton sx={{ padding: 0.5 }} onClick={(e) => { e.stopPropagation(); handleVideoSelect(subVideo); }}>
              <PlayArrowIcon />
            </IconButton>
          </Tooltip> */}

          {isThereAQuiz(subVideo.id) && canAccessQuiz && <Tooltip title="Take Quiz">
            <IconButton sx={{ padding: 0.5, color: 'black' }} onClick={(e) => { e.stopPropagation(); handleQuizButtonClick({ ...subVideo, articleId: mainVideo.movieId, topicNumber: subVideo.id }, mainVideo.movieId, subVideo.id, e); }}>
              <AssignmentIcon />
            </IconButton>
          </Tooltip>}
          <span style={{ marginLeft: '-3px' }}></span>
          <Tooltip title="Add to Playlist">
            <IconButton sx={{ padding: 0.5, color: 'black' }} onClick={(e) => handleAddToPlaylist(subVideo)}>
              <PlaylistAddIcon />
            </IconButton>
          </Tooltip>
          <span style={{ marginLeft: '-5px' }}></span>
          <Tooltip title={isExpanded ? "Hide Summary" : "Show Summary"}>
            <IconButton sx={{ padding: 0.5, color: 'black' }} onClick={toggleExpand}>
              {isExpanded ? <RemoveIcon /> : <AddIcon />}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {isExpanded && (
        <Box sx={{ marginBottom: '10px' }}>
          {/* Topics Section */}
          {subVideo.subSubject?.trim() && (
            <Paper sx={{ mt: 0.5, p: 1, border: '1px solid gray', borderRadius: '4px' }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                {isHebrew(mainVideo.subject) ? 'נושאים' : 'Topics'}
              </Typography>
              {subVideo.subSubject.split(',').map((sentence: string, subIndex: number) => (
                <Typography
                  key={subIndex}
                  sx={{
                    fontSize: '14px',
                    ml: 0.5,
                    lineHeight: 1.2,
                    marginLeft: isHebrew(mainVideo.subject) ? '0' : '20px',
                    marginRight: isHebrew(mainVideo.subject) ? '20px' : '0'
                  }}
                  variant="body2"
                  color="text.secondary"
                >
                  {`${subIndex + 1}. ${sentence.trim()}`}
                </Typography>
              ))}
            </Paper>
          )}

          {/* Summary Section */}
          {subVideo.summary?.trim() && (
            <Paper sx={{ mt: 1, p: 1, border: '1px solid gray', borderRadius: '4px' }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
                {isHebrew(mainVideo.subject) ? 'סיכום' : 'Summary'}
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  mt: 0.5,
                  lineHeight: 1.2,
                  marginBottom: '5px',
                  marginLeft: isHebrew(mainVideo.subject) ? '0' : '20px',
                  marginRight: isHebrew(mainVideo.subject) ? '20px' : '0'
                }}
                variant="body2"
                color="text.secondary"
              >
                {subVideo.summary}
              </Typography>
            </Paper>
          )}
        </Box>

      )
      }

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 0.5, ml: 3, marginRight: isHebrew(mainVideo.subject) ? '0' : '35px', marginLeft: isHebrew(mainVideo.subject) ? '5px' : '', marginTop: '-2px' }}>
        {/* {subVideo.startTime && subVideo.endTime ? <AccessTimeIcon sx={{ fontSize: '15px', marginRight: '1px', marginLeft: '1px' }} /> : <PictureAsPdfIcon sx={{ fontSize: '15px', marginRight: '1px', marginLeft: '1px' }} />} */}
        <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }} variant="body2" color="text.secondary">
          {formatTimeOrPagesInternal(subVideo)}
        </Typography>
      </Box>
    </Paper >
  );
});

export default SubObjectComponent;
