


export const hasAccess = (pageName: string, componentName: string) => {
  const permissions = localStorage.getItem('permissions') ? JSON.parse(localStorage.getItem('permissions') as string) : null;
  const canAccess = permissions[pageName]?.[componentName]?.canAccess ?? false;
  return canAccess;
}

export const menuPermissions = () => {
  try {
    const menuPermissions = localStorage.getItem('menuPermissions');
    if (menuPermissions) {
      return JSON.parse(menuPermissions);
    }
    return null; // Return null if no menuPermissions found
  } catch (error) {
    console.error('Error parsing menu permissions:', error);
    return null; // Return null if parsing fails
  }
};