import React, { useState, useEffect } from 'react';
import { getUsers } from '../api/serverApis/userApi';
import { sendMessage, getMessagesForUser, getMessageWithAttachments, markMessageAsReadApi, getAllMessagesBySenderIdApi } from '../api/serverApis/messagingApi';
import { SelectChangeEvent } from '@mui/material';
import { Box, Typography, Paper, Button, Select, MenuItem, FormControl, InputLabel, Divider, List, ListItem, ListItemText, TextField, Accordion, AccordionSummary, AccordionDetails, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SendIcon from '@mui/icons-material/Send';
import Header from '../components/combinationSearchComponents/Header';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDropzone } from 'react-dropzone';
import { fetchUnreadCount } from '../features/messagingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../store';
import RichEditor from '../components/messaging/RichEditor';
import { Autocomplete, Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
interface Message {
    messageId: string;
    sender: string;
    recipient: string;
    subject: string;
    body: string;
    attachments?: File[];
    createdAt: Date;
    isRead: boolean;
    userEmail: string | null;
}
const isHebrewOrArabic = (text: string) => /[\u0590-\u05FF\uFB1D-\uFB4F\uFB50-\uFDFF\u0600-\u06FF]/.test(text);
const MessagingPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [themeMode, setThemeMode] = useState<'light' | 'dark'>('light');
    const [subject, setSubject] = useState<string>('');
    const [body, setBody] = useState<string>('');
    const [attachments, setAttachments] = useState<File[]>([]);
    const [messages, setMessages] = useState<Message[]>([]);
    const [sentMessages, setSentMessages] = useState<Message[]>([]);
    const [viewingMessage, setViewingMessage] = useState<Message | null>(null);
    const [viewMode, setViewMode] = useState<'send' | 'view' | 'sent'>('view');
    const [searchText, setSearchText] = useState<string>('');
    const [users, setUsers] = useState<any[]>([]);
    const [showUnread, setShowUnread] = useState<boolean>(false);
    const [recipient, setRecipient] = useState<string[]>([]);
    const permissions = JSON.parse(localStorage.getItem('permissions') || '{}');
    const canAccessComposeMessage = permissions?.MessagingPage?.composeMessage?.canAccess;
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await getUsers();
                setUsers(response.data);
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };

        fetchUsers();
    }, []);
    const handleViewModeChange = (mode: 'view' | 'sent' | 'send') => {
        setViewMode(mode);
        setViewingMessage(null);
        setSearchText(''); // Clear search text
        setShowUnread(false); // Reset unread filter

        // Clear the opposite message list to avoid stale data
        if (mode === 'view') {
            setSentMessages([]); // Clear sent messages
        } else if (mode === 'sent') {
            setMessages([]); // Clear received messages
        }
    };
    const fetchMessages = async () => {
        try {
            const response = await getMessagesForUser();
            const sortedMessages = response.data.sort((a: Message, b: Message) =>
                new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );

            setMessages(sortedMessages);
        } catch (error) {
            console.error("Error fetching messages:", error);
        }
    };

    const fetchSentMessages = async () => {
        try {
            const response = await getAllMessagesBySenderIdApi(); // Adjust API call as needed
            const sortedSentMessages = response.data.sort((a: Message, b: Message) =>
                new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );

            setSentMessages(sortedSentMessages);
        } catch (error) {
            console.error("Error fetching sent messages:", error);
        }
    };

    useEffect(() => {
        // Fetch messages initially
        fetchMessages();

        // Set up an interval to fetch messages every 20 seconds
        const interval = setInterval(() => {
            fetchMessages();
            dispatch(fetchUnreadCount());
        }, 600000);

        // Clean up the interval when the component unmounts
        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        const fetchCurrentViewMessages = async () => {
            if (viewMode === 'view') {
                await fetchMessages();
            }
            else if (viewMode === 'sent') {
                await fetchSentMessages();
            }
        };

        fetchCurrentViewMessages();
    }, [viewMode]);

    const toggleTheme = () => {
        setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    };

    const handleSendMessage = async () => {
        if (subject && body) {
            try {
                const isBroadcast = recipient.includes('all'); // Check if 'all' is included in the recipient array
                const data = {
                    recipientId: isBroadcast ? null : recipient, // Pass null if 'all' is selected, otherwise pass the array
                    subject,
                    body,
                    isBroadcast,
                    attachments,
                };

                await sendMessage(data);

                // After sending, fetch updated messages
                fetchMessages();

                // Clear fields
                setRecipient([]); // Clear recipients
                setSubject('');
                setBody('');
                setAttachments([]);
                setViewMode('view');
            } catch (error) {
                console.error('Error sending message:', error);
            }
        }
    };
    const handleUserChange = (event: SelectChangeEvent<string[]>) => {
        const selected = event.target.value as string[]; // Explicitly cast to string[]

        // If "all" is selected, clear all other selections and only select "all"
        if (selected.includes("all")) {
            if (recipient.includes("all")) {
                // If "all" was already selected, allow toggling it off
                setRecipient([]);
            } else {
                setRecipient(["all"]);
            }
        } else {
            // Otherwise, update the state with the selected values
            setRecipient(selected.filter((id) => id !== "all")); // Exclude "all" if individual users are selected
        }
    };
    const onDrop = (acceptedFiles: File[]) => {
        setAttachments([...attachments, ...acceptedFiles]);
    };
    const toggleUnreadMessages = async () => {
        setShowUnread(false);
        setSentMessages([]);
        setMessages([]);
        if (viewMode === 'view') {
            await fetchMessages();
        }
        else if (viewMode === 'sent') {
            await fetchSentMessages();
        }
        getDisplayedMessages();
        setShowUnread(!showUnread);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    // Filtered messages based on search text
    const getDisplayedMessages = () => {
        const currentMessages = viewMode === 'view' ? messages : sentMessages;
        return currentMessages.filter(
            (msg) =>
                (showUnread ? !msg.isRead : true) &&
                (msg.subject.toLowerCase().includes(searchText.toLowerCase()) ||
                    msg.body.toLowerCase().includes(searchText.toLowerCase()))
        );
    };

    const markMessageAsRead = async (messageId: string) => {
        try {
            if (viewMode === 'sent') return;
            // Update the local state for immediate UI feedback
            setMessages((prevMessages) =>
                prevMessages.map((msg) =>
                    msg.messageId === messageId ? { ...msg, isRead: true } : msg
                )
            );

            // Mark the message as read in the backend
            await markMessageAsReadApi(messageId);

            await dispatch(fetchUnreadCount());
            // Fetch fresh messages to ensure synchronization
            await fetchMessages();

        } catch (error) {
            console.error("Error marking message as read:", error);
        }
    };
    //{/* Left Menu */}
    const leftMenu = () => {

        return (
            <Box sx={{ width: 200, padding: 2, borderRight: '1px solid #ddd' }}>
                {canAccessComposeMessage && (
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => handleViewModeChange('send')}
                        sx={{ mb: 2 }}
                    >
                        Compose Message
                    </Button>
                )}
                <Typography variant="h6" gutterBottom>Menu</Typography>
                <List>
                    <ListItem
                        button
                        onClick={() => handleViewModeChange('view')}
                        selected={viewMode === 'view'}
                    >
                        <ListItemText primary="Inbox" />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => handleViewModeChange('sent')}
                        selected={viewMode === 'sent'}
                    >
                        <ListItemText primary="Sent Mails" />
                    </ListItem>
                </List>
            </Box>
        );
    }
    //{/* Filter/Search Section/and message List */}
    const filterText = () => {
        return (
            <TextField
                label="Search Messages"
                variant="outlined"
                fullWidth
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                sx={{ mb: 2 }}
            />
        );
    }
    const unreadMessageOrAllMessageButton = () => {

        return (
            <Button
                variant="contained"
                color={showUnread ? 'secondary' : 'primary'}
                onClick={toggleUnreadMessages}
            >
                {showUnread ? 'All Messages' : 'Unread Messages'}
            </Button>
        );
    }
    const messageList = () => {
        const displayedMessages = getDisplayedMessages();
        return (
            <List>
                {displayedMessages.map((message: any, index: number) => (
                    <ListItem
                        key={message.messageId}
                        button
                        selected={viewingMessage?.messageId === message.messageId}
                        onClick={() => {
                            setViewingMessage(message);

                            // Call `markMessageAsRead` only if the message is unread
                            if (!message.isRead) {
                                markMessageAsRead(message.messageId);
                            }// Mark the message as read
                        }}
                        sx={{
                            borderBottom: '1px solid #ddd', // Divider between messages
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.05)', // Highlight on hover
                            },
                            boxShadow: viewingMessage?.messageId === message.messageId ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none', // Subtle shadow for selected message
                            transition: 'box-shadow 0.2s ease', // Smooth shadow transition
                        }}
                    >
                        <ListItemText
                            primary={
                                <Typography
                                    sx={{
                                        fontWeight: message.isRead ? 'normal' : 'bold', // Bold for unread messages
                                        color: message.isRead ? 'text.primary' : 'primary.main', // Use theme colors
                                        fontSize: '1.2rem', // Slightly larger font for the subject
                                    }}
                                >
                                    {message.subject}
                                </Typography>
                            }
                            secondary={
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
                                    <Typography
                                        sx={{
                                            color: 'text.secondary',
                                            fontSize: '0.9rem', // Medium font size
                                            fontWeight: 500, // Slightly bolder for emphasis
                                        }}
                                    >
                                        {`${itIsFromOrItIsTo()}: ${message.userEmail}`}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: 'text.secondary',
                                            fontSize: '0.7rem', // Slightly smaller font
                                        }}
                                    >
                                        {` ${new Date(message.createdAt).toLocaleString('en-US', {
                                            year: 'numeric',
                                            month: 'short',
                                            day: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true,
                                        })}`}
                                    </Typography>
                                    {!message.isRead && (
                                        <Box
                                            sx={{
                                                backgroundColor: 'primary.main',
                                                color: 'white',
                                                borderRadius: '50%',
                                                width: 10,
                                                height: 10,
                                                alignSelf: 'flex-start',
                                            }}
                                        />
                                    )}
                                </Box>
                            }
                        />
                    </ListItem>
                ))}
            </List>
        );

    }
    // const sentMessageList = () => {
    //     return (
    //         <List>
    //             {displayedMessages.map((message: any, index: number) => (
    //                 <ListItem
    //                     key={message.messageId}
    //                     button
    //                     selected={viewingMessage?.messageId === message.messageId}
    //                     onClick={() => {
    //                         setViewingMessage(message);

    //                         // Call `markMessageAsRead` only if the message is unread
    //                         if (!message.isRead) {
    //                             markMessageAsRead(message.messageId);
    //                         }// Mark the message as read
    //                     }}
    //                     sx={{
    //                         borderBottom: '1px solid #ddd', // Divider between messages
    //                         '&:hover': {
    //                             backgroundColor: 'rgba(0, 0, 0, 0.05)', // Highlight on hover
    //                         },
    //                         boxShadow: viewingMessage?.messageId === message.messageId ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none', // Subtle shadow for selected message
    //                         transition: 'box-shadow 0.2s ease', // Smooth shadow transition
    //                     }}
    //                 >
    //                     <ListItemText
    //                         primary={
    //                             <Typography
    //                                 sx={{
    //                                     fontWeight: message.isRead ? 'normal' : 'bold', // Bold for unread messages
    //                                     color: message.isRead ? 'text.primary' : 'primary.main', // Use theme colors
    //                                     fontSize: '1.2rem', // Slightly larger font for the subject
    //                                 }}
    //                             >
    //                                 {message.subject}
    //                             </Typography>
    //                         }
    //                         secondary={
    //                             <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
    //                                 <Typography
    //                                     sx={{
    //                                         color: 'text.secondary',
    //                                         fontSize: '0.9rem', // Medium font size
    //                                         fontWeight: 500, // Slightly bolder for emphasis
    //                                     }}
    //                                 >
    //                                     {`${itIsFromOrItIsTo()}: ${message.userEmail}`}
    //                                 </Typography>
    //                                 <Typography
    //                                     sx={{
    //                                         color: 'text.secondary',
    //                                         fontSize: '0.7rem', // Slightly smaller font
    //                                     }}
    //                                 >
    //                                     {` ${new Date(message.createdAt).toLocaleString('en-US', {
    //                                         year: 'numeric',
    //                                         month: 'short',
    //                                         day: '2-digit',
    //                                         hour: '2-digit',
    //                                         minute: '2-digit',
    //                                         hour12: true,
    //                                     })}`}
    //                                 </Typography>
    //                                 {!message.isRead && (
    //                                     <Box
    //                                         sx={{
    //                                             backgroundColor: 'primary.main',
    //                                             color: 'white',
    //                                             borderRadius: '50%',
    //                                             width: 10,
    //                                             height: 10,
    //                                             alignSelf: 'flex-start',
    //                                         }}
    //                                     />
    //                                 )}
    //                             </Box>
    //                         }
    //                     />
    //                 </ListItem>
    //             ))}
    //         </List>
    //     );

    // }
    //{/* Main Content = view send or received messages or compose mail*/}
    const viewModeDecision = () => {
        if (viewMode === 'view') {
            return (<Box>{viewingMessage ? <Box>{viewMessages()}{viewMessageAttachments()}</Box> : <Typography color="textSecondary">Select a message to view</Typography>}</Box>);
        }
        if (viewMode === 'sent') {
            return (<Box>{viewingMessage ? <Box>{viewMessages()}{viewMessageAttachments()}</Box> : <Typography color="textSecondary">Select a message to view</Typography>}</Box>);
        }
        if (viewMode === 'send') {
            return (<Box>{composeMail()}</Box>)
        }
    }
    const itIsFromOrItIsTo = () => {
        if (viewMode === 'view') {
            return 'From'
        }
        if (viewMode === 'sent') {
            return 'To'
        }
    }
    const viewMessageOrSentMessage = () => {
        return messageList();
        // if (viewMode === 'view') {
        //     return messageList();
        // }
        // if (viewMode === 'sent') {
        //     return sentMessageList();
        // }
    }
    const viewMessages = () => {
        if (viewingMessage) {
            return (
                <Box>
                    <Typography variant="h6" gutterBottom>
                        {`${itIsFromOrItIsTo()}: ${viewingMessage.userEmail}`}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                        {`Subject: ${viewingMessage.subject}`}
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    <Box
                        sx={{
                            fontSize: '0.9rem',
                            color: 'text.primary',
                            lineHeight: 1.6,
                            overflowWrap: 'break-word',
                            direction: isHebrewOrArabic(viewingMessage.body) ? 'rtl' : 'ltr', // Set text direction dynamically
                            textAlign: isHebrewOrArabic(viewingMessage.body) ? 'right' : 'left', // Align text accordingly
                            img: {
                                maxWidth: '100%',
                                height: 'auto',
                                borderRadius: '4px',
                            },
                            p: {
                                margin: '0 0 10px',
                            },
                            span: {
                                wordBreak: 'break-word',
                            },
                        }}
                        dangerouslySetInnerHTML={{ __html: viewingMessage.body }}
                    />
                </Box>
            );
        }
    }
    const viewMessageAttachments = () => {
        if (viewingMessage?.attachments) {
            return (
                <Box>
                    {
                        viewingMessage.attachments?.map((file: any, idx: any) => (
                            <Typography key={idx} variant="body2" color="primary">
                                <a href={URL.createObjectURL(file)} download>
                                    {file.name}
                                </a>
                            </Typography>
                        ))
                    }
                </Box>
            )
        }
    }
    const composeMail = () => {
        return (
            <Box>
                <Typography variant="h6" gutterBottom>
                    Compose Message
                </Typography>
                <Divider sx={{ mb: 2 }} />

                <Autocomplete
                    multiple
                    options={[{ userId: 'all', email: 'All Users' }, ...users]}
                    getOptionLabel={(option) => option.email}
                    value={
                        recipient.includes('all')
                            ? [{ userId: 'all', email: 'All Users' }]
                            : users.filter((user) => recipient.includes(user.userId))
                    }
                    onChange={(event, newValue) => {
                        if (newValue.some((option) => option.userId === 'all')) {
                            setRecipient(['all']);
                        } else {
                            setRecipient(newValue.map((option) => option.userId));
                        }
                    }}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.email}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Recipients"
                            placeholder="Select recipients"
                        />
                    )}
                />

                <TextField
                    label="Subject"
                    fullWidth
                    variant="outlined"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    sx={{ mb: 2, mt: 2 }}
                />

                <RichEditor value={body} onChange={setBody} />

                {attachments.map((file, index) => (
                    <Typography key={index} variant="body2" color="textSecondary">
                        {file.name}
                    </Typography>
                ))}

                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSendMessage}
                    sx={{ mt: 2 }}
                    startIcon={<SendIcon />}
                    disabled={!subject || !body}
                >
                    Send Message
                </Button>
            </Box>
        );
    };
    return (
        <Box sx={{ height: '97.5vh', display: 'flex', flexDirection: 'column' }}>
            <Header header="Messaging" themeMode={themeMode} toggleTheme={toggleTheme} title="" />
            <Box sx={{ flex: 1, display: 'flex' }}>

                {/* Left Menu */}

                {leftMenu()}
                {/* Main Content */}
                <Box sx={{ flex: 1, display: 'flex', padding: 3 }}>
                    <Paper
                        elevation={3}
                        sx={{
                            width: '20%',
                            left: '245px',
                            position: 'fixed', // Keeps the section fixed
                            top: '90px', // Adjusted for header height
                            bottom: '0px', // Fixed to the bottom of the viewport
                            padding: 2,
                            overflowY: 'auto', // Adds vertical scrolling
                            mr: 2,
                            scrollbarWidth: 'thin', // For Firefox
                            scrollbarColor: 'blue lightgray', // For Firefox
                            '&::-webkit-scrollbar': {
                                width: '2px', // Thinner scrollbar
                            },
                            '&::-webkit-scrollbar-track': {
                                background: 'lightgray', // Track color
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'blue', // Scrollbar thumb color
                                borderRadius: '10px', // Rounded edges
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                                backgroundColor: 'darkblue', // Darker color on hover
                            },
                        }}
                    >
                        <Typography variant="h6" gutterBottom>Messages</Typography>
                        <Divider sx={{ mb: 2 }} />
                        {/* Filter/Search Section */}
                        {filterText()}
                        {unreadMessageOrAllMessageButton()}
                        {viewMessageOrSentMessage()}
                    </Paper>

                    {/* Message Viewer / Composer */}
                    <Paper
                        elevation={3}
                        sx={{
                            width: '57%',
                            left: '570px',
                            position: 'fixed', // Keeps the section fixed
                            top: '90px', // Adjusted for header height
                            bottom: '0px', // Fixed to the bottom of the viewport
                            padding: 2,
                            overflowY: 'auto', // Adds vertical scrolling
                            mr: 2,
                            scrollbarWidth: 'thin', // For Firefox
                            scrollbarColor: 'blue lightgray', // For Firefox
                            '&::-webkit-scrollbar': {
                                width: '2px', // Thinner scrollbar
                            },
                            '&::-webkit-scrollbar-track': {
                                background: 'lightgray', // Track color
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'blue', // Scrollbar thumb color
                                borderRadius: '10px', // Rounded edges
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                                backgroundColor: 'darkblue', // Darker color on hover
                            },
                        }}
                    >
                        {viewModeDecision()}
                    </Paper>
                </Box>
            </Box>
        </Box>
    );
};

export default MessagingPage;
