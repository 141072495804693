import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { checkAuthenticated } from './api/serverApis/userApi';
import { useNavigate } from 'react-router-dom';
interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredPermission?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, requiredPermission }) => {
  // const [isAuthChecked, setIsAuthChecked] = useState(false);
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthChecked, setIsAuthChecked] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [hasPermission, setHasPermission] = useState(true);
  const navigate = useNavigate();
  const authenticate = async () => {
    try {
      const response = await checkAuthenticated(); // Assuming this function exists and returns a promise
      if (response.data.data === 'success') {
        setIsAuthenticated(true);
        // Check permissions if requiredPermission is provided
        if (requiredPermission) {
          const permissions = JSON.parse(localStorage.getItem('permissions') || '{}');
          // Access the required permission dynamically based on provided path
          const permissionPath = requiredPermission.split('.').reduce((obj, key) => obj?.[key], permissions) || {};
          setHasPermission(permissionPath.canAccess === true);
        } else {
          setHasPermission(true);
        }
      } else {
        setIsAuthChecked(true);
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error(error);
      setIsAuthChecked(true);
      setIsAuthenticated(false);
    }
    setIsAuthChecked(true);
  };

  useEffect(() => {


    authenticate();
  }, []);
  // authenticate();

  if (!isAuthChecked) {
    // Optionally render a loading indicator while checking auth
    return <div>Checking authentication...</div>;
  }

  if (!isAuthenticated) {
    // Redirect to login page if not authenticated
    return <Navigate to="/login" replace />;
  }
  if (!hasPermission) {
    // Redirect if no permission
    navigate('/home');
  }
  return <>{children}</>;
};

export default ProtectedRoute;