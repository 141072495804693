import React, { useState, useEffect, useRef } from 'react';
import { reportOnQuestion, createMonitoringUserAnswerQuiz, createMonitoringUserOpenAnswerQuiz } from '../../../api/serverApis/monitoringApi';
import { Box, Radio, RadioGroup, FormControlLabel, FormControl, Button, Divider, Typography, Tooltip, IconButton, Menu, MenuItem } from '@mui/material';
import MainQuestionHtmlShow from './MainQuestionHtmlShow';
import VideoBoxInteract from '../VideoBoxInteract';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CancelIcon from '@mui/icons-material/Cancel';
import { useVideoProgress } from '../videoPlayer/VideoProgressContext';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import UnpublishedIcon from '@mui/icons-material/Unpublished';

interface MainQuestionComponentProps {
  quizData: any;
  theme: any;
  startTime?: string;
  endTime?: string;
  videoData: any;
  onBackToVideo: () => void;
  onNextQuizSet: () => void;
  onPreviousQuizSet: () => void;
  disableNext: boolean;
  disablePrevious: boolean;
  handleGetQuizData: (movieId: string, topicNumber: string) => void;
  getQuizFromPlayer: boolean;
}

const MainQuestionComponent: React.FC<MainQuestionComponentProps> = ({ quizData, theme, startTime, endTime, videoData, onBackToVideo, onNextQuizSet, onPreviousQuizSet, disableNext, disablePrevious, handleGetQuizData, getQuizFromPlayer }) => {
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number>(0);
  const [showAnswer, setShowAnswer] = useState<boolean>(false);
  const [showMovie, setShowMovie] = useState<boolean>(false);
  const [radioGroupHeight, setRadioGroupHeight] = useState<number>(0);
  const [openVideoInteract, setOpenVideoInteract] = useState(false);
  const lightColors = ['#F0F0F0', '#E0E0E0'];
  const darkColors = ['#333333', '#404040'];
  const colors = theme.palette.mode === 'dark' ? darkColors : lightColors;
  //const { setCurrentlyPlayingSubVideo } = useVideoProgress();
  const radioGroupRef = useRef<HTMLDivElement>(null);
  const [reportColor, setReportColor] = useState<string>('default');
  const [solvedColor, setSolvedColor] = useState<string>('default');
  const [notSolvedColor, setNotSolvedColor] = useState<string>('default');
  const [notSolved, setNotSolved] = useState<boolean>(false);
  const [solved, setSolved] = useState<boolean>(false);
  const [nextSetOfQuestion, setNextSetOfQuestion] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null); // NEW CODE: Track selected option
  const [showFeedback, setShowFeedback] = useState<boolean>(false);

  const sortedAnswerData = quizData;

  useEffect(() => {
    if (sortedAnswerData.length > 0) {
      setSelectedQuestionIndex(0);
    }
  }, [nextSetOfQuestion, getQuizFromPlayer]);

  useEffect(() => {
    if (sortedAnswerData.length > 0) {
      const currentQuestion = sortedAnswerData[selectedQuestionIndex];

      // Check if there are any votes that should trigger an icon color change
      if (currentQuestion.problemVote > 0 || currentQuestion.typoVote > 0 || currentQuestion.questionNotGoodVote > 0 || currentQuestion.answerNotGoodVote > 0) {
        setReportColor('red');
      }
      else {
        setReportColor('default');
      }
      if (currentQuestion.didntSolveVote > 0) {
        setNotSolved(true);
        setNotSolvedColor('orange');
      }
      else {
        setNotSolved(false);
        setNotSolvedColor('default');
      }
      if (currentQuestion.easyQuestionVote > 0 || currentQuestion.mediumQuestionVote > 0 || currentQuestion.hardQuestionVote > 0 || currentQuestion.solvedVote > 0) {
        setSolved(true);
        setSolvedColor('green');
      }
      else {
        setSolved(false);
        setSolvedColor('default');
      }
    }
  }, [sortedAnswerData, selectedQuestionIndex]);

  // useEffect(() => {
  //   setCurrentlyPlayingSubVideo({ movieId: videoData.articleId, subVideoId: videoData.id });
  // }, [videoData]);

  // useEffect(() => {
  //   if (radioGroupRef.current) {
  //     setRadioGroupHeight(radioGroupRef.current.clientHeight);
  //   }
  // }, [radioGroupRef.current]);

  const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedQuestionIndex(parseInt(event.target.value, 10));
    setShowAnswer(false); // Close the answer when a new question is selected
    setShowFeedback(false); // Reset feedback when switching questions
    setSelectedOption(null); // Reset selected option
  };
  const handleOptionSelect = (option: string, selectedQuestion: any) => {
    setSelectedOption(option);
    setShowFeedback(true); // Show feedback after an option is selected
    createMonitoringUserAnswerQuiz(selectedQuestion.questionId, selectedQuestion.articleId, selectedQuestion.topicNumber, selectedQuestion.topic, option, selectedQuestion.multiChoiceAnswerJson);
  };
  const handleNextQuestion = () => {
    setSelectedQuestionIndex((prevIndex) => (prevIndex + 1) % sortedAnswerData.length);
    setShowAnswer(false); // Close the answer when navigating to the next question
    setShowAnswer(false); // Hide answer
    setShowFeedback(false); // Reset feedback
    setSelectedOption(null); // Reset selected option (clear radio button selection)
  };


  const handlePreviousQuestion = () => {
    setSelectedQuestionIndex((prevIndex) => (prevIndex - 1 + sortedAnswerData.length) % sortedAnswerData.length);
    setShowAnswer(false); // Close the answer when navigating to the previous question
    setShowAnswer(false); // Hide answer
    setShowFeedback(false); // Reset feedback
    setSelectedOption(null); // Reset selected option (clear radio button selection)
  };
  const handleToggleMovie = () => {
    setShowMovie(!showMovie);
    setOpenVideoInteract(true);
  };
  const handleShowAnswer = (selectedQuestion: any) => {
    setShowAnswer(!showAnswer);
    createMonitoringUserOpenAnswerQuiz(selectedQuestion.questionId, selectedQuestion.articleId, selectedQuestion.topicNumber, selectedQuestion.topic);
  };

  const handleNextSetOfQuestions = () => {
    setNextSetOfQuestion(!nextSetOfQuestion);
    onNextQuizSet();
  }
  const handlePerviousSetOfQuestions = () => {
    setNextSetOfQuestion(!nextSetOfQuestion);
    onPreviousQuizSet();
  }
  const isHebrewOrArabic = (text: string) => /[\u0590-\u05FF\uFB1D-\uFB4F\uFB50-\uFDFF\u0600-\u06FF]/.test(text);

  const selectedQuestion = sortedAnswerData[selectedQuestionIndex] || {};
  const multiChoiceOptions = (() => {
    try {
      if (!selectedQuestion || !selectedQuestion.multiChoiceJson) {
        console.warn('Selected question or multiChoiceJson is undefined');
        return null;
      }
      return typeof selectedQuestion.multiChoiceJson === 'string'
        ? JSON.parse(selectedQuestion.multiChoiceJson)
        : selectedQuestion.multiChoiceJson;
    } catch (error) {
      console.error('Error parsing multiChoiceJson:', error);
      return null; // Return null if parsing fails
    }
  })();

  const correctAnswer = (() => {
    try {
      const rawJson = selectedQuestion?.multiChoiceAnswerJson;
      if (rawJson) {
        // Replace smart quotes with standard quotes
        const sanitizedJson = rawJson.replace(/[\u201C\u201D]/g, '"');
        return JSON.parse(sanitizedJson).correctChoice;
      }
      return null;
    } catch (error) {
      console.error('Error parsing multiChoiceAnswerJson:', error);
      return null; // Return null if parsing fails
    }
  })();

  const languageLabel = isHebrewOrArabic(selectedQuestion?.topic || '') ? 'תרגיל' : 'Exercise';
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleReportClick = (event: React.MouseEvent<HTMLElement>) => {
    setReportColor('red');
    setAnchorEl(event.currentTarget);
  };

  const handleReportClose = () => {
    setAnchorEl(null);
  };

  const handleReportSelect = async (reportType: string) => {
    try {
      if (reportType === 'there is no problem') {
        setReportColor('default');
      }
      handleReportClose();
      await reportOnQuestion(videoData.articleId, videoData.id, selectedQuestion.questionId, reportType);
      handleGetQuizData(videoData.articleId, videoData.id);
    }
    catch (error) {
      console.error('MainQuestionComponent: [handleReportSelect]: ' + error);
    }
  };

  const [solvedAnchorEl, setSolvedAnchorEl] = useState<null | HTMLElement>(null);

  const handleSolvedClick = (event: React.MouseEvent<HTMLElement>) => {
    try {
      setSolved(!solved);
      setSolvedColor(solved ? 'default' : 'green');
      setSolvedAnchorEl(event.currentTarget);
      const difficulty = solved ? '' : 'solved';
      //reportOnQuestion(videoData.articleId, videoData.id, selectedQuestion.questionId, difficulty);
    }
    catch (error) {
      console.error('MainQuestionComponent: [handleSolvedClick]: ' + error);
    }
  };

  const handleSolvedClose = () => {
    setSolvedAnchorEl(null);
  };

  const handleSolvedSelect = async (difficulty: string) => {
    try {
      console.log('Solved difficulty:', difficulty);
      handleSolvedClose();
      await reportOnQuestion(videoData.articleId, videoData.id, selectedQuestion.questionId, difficulty);
      handleGetQuizData(videoData.articleId, videoData.id);
    }
    catch (error) {
      console.error('MainQuestionComponent: [handleSolvedSelect]: ' + error);
    };
  };
  const handleNotSolvedClick = async () => {
    try {
      setNotSolved(!notSolved);
      setNotSolvedColor(notSolved ? 'default' : 'orange');
      await reportOnQuestion(videoData.articleId, videoData.id, selectedQuestion.questionId, notSolved ? '' : 'didntSolve');
      handleGetQuizData(videoData.articleId, videoData.id);
    }
    catch (error) {
      console.error('MainQuestionComponent: [handleNotSolvedClick]: ' + error);
    }
  };
  const renderMultipleChoiceOptions = (options: Record<string, string>, correctOption: string, selectedOption: string | null, feedback: boolean, selectedQuestion: any) => {
    const directionMain = isHebrewOrArabic(multiChoiceOptions.optionA) ? 'rtl' : 'ltr';

    return (
      <Box
        sx={{
          marginTop: '100px',
          textAlign: 'center',
          direction: directionMain,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center', // Center-align the options
          gap: '10px', // Add spacing between rows and columns
        }}
      >
        <FormControl component="fieldset">
          <RadioGroup
            name="multiple-choice"
            value={selectedOption}
            onChange={(e) => handleOptionSelect(e.target.value, selectedQuestion)}
            sx={{
              display: 'flex',
              flexDirection: 'row', // Align in a row for two items per row
              flexWrap: 'wrap', // Allow wrapping to the next line
              justifyContent: 'center',
              gap: '20px',
            }}
          >
            {Object.entries(options).map(([key, value]: [string, string], index) => {
              const direction = isHebrewOrArabic(value) ? 'rtl' : 'ltr';
              const optionLabel = String.fromCharCode(65 + index); // Convert 0,1,2,3 to A,B,C,D
              return (
                <Box
                  key={key}
                  sx={{
                    width: '45%', // Make each option take 45% of the container width for two items per row
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '10px',
                    border: `2px solid ${selectedOption === key
                      ? key === correctOption
                        ? theme.palette.success.main
                        : theme.palette.error.main
                      : theme.palette.divider
                      }`,
                    borderRadius: '5px',
                    backgroundColor: 'inherit',
                    position: 'relative',
                  }}
                >
                  {/* Letter before the radio button */}
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      marginRight: '10px',
                      fontSize: '1.1rem',
                      direction,
                    }}
                  >
                    {optionLabel}
                  </Typography>

                  {/* Radio button and label */}
                  <FormControlLabel
                    value={key}
                    control={
                      <Radio
                        size="small" // Make the radio buttons smaller
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          direction,
                          textAlign: direction === 'rtl' ? 'right' : 'left',
                          fontSize: '0.9rem', // Adjust font size for labels
                        }}
                      >
                        {value}
                      </Typography>
                    }
                    sx={{ flex: 1 }}
                  />

                  {/* Feedback icon (✔️ or ❌) */}
                  {feedback && selectedOption === key && (
                    <Box
                      sx={{
                        position: 'absolute',
                        right: '5px',
                        top: '20%',
                        transform: 'translateY(-50%)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {key === correctOption ? (
                        <CheckCircleIcon
                          sx={{ color: theme.palette.success.main, fontSize: '20px' }}
                        />
                      ) : (
                        <HighlightOffIcon
                          sx={{ color: theme.palette.error.main, fontSize: '20px' }}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              );
            })}
          </RadioGroup>
        </FormControl>
      </Box>
    );
  };


  return (
    <Box sx={{ margin: 'auto', mt: 4, width: '98%', paddingLeft: '50px', marginBlock: '3rem', marginLeft: '-1.5rem', overflowY: 'hidden', }} >
      <Box sx={{ position: 'fixed', top: 49, left: 0, width: '59.5%', display: 'flex', justifyContent: 'space-between', backgroundColor: theme.palette.background.paper, zIndex: 100, padding: 2 }}>
        <Typography variant="h6" color="textPrimary" sx={{ margin: 'auto' }}>
          {selectedQuestion ? selectedQuestion.topic : ''}
        </Typography>
      </Box>
      {/* <Box sx={{ position: 'fixed', top: 9, left: 0, width: '61%', display: 'flex', justifyContent: 'space-between', backgroundColor: theme.palette.background.default, zIndex: 90, padding: 1.55 }} ></Box> */}

      <Box sx={{ position: 'fixed', top: 90, left: 0, width: '59.5%', display: 'flex', justifyContent: 'space-between', backgroundColor: theme.palette.background.paper, zIndex: 100, padding: 2 }} ref={radioGroupRef}>
        <FormControl component="fieldset">
          <RadioGroup row aria-label="quiz questions" name="quiz-questions" value={selectedQuestionIndex} onChange={handleQuestionChange}>
            {sortedAnswerData.map((item: any, index: any) => (
              <FormControlLabel
                key={index}
                value={index}
                control={<Radio />}
                label={index + 1}
                sx={{
                  '& .MuiFormControlLabel-label': {
                    textAlign: isHebrewOrArabic(item.topic) ? 'right' : 'left',
                  },
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
      <Box sx={{ marginTop: `${radioGroupHeight + 90}px` }}>

        {selectedQuestion && (
          <Box sx={{ padding: '20px', marginLeft: '30px', borderRadius: '5px', backgroundColor: 'none' }}>
            <Typography variant="h6" sx={{ marginBottom: 2, direction: isHebrewOrArabic(languageLabel) ? 'rtl' : 'ltr', textAlign: 'center' }}>
              {`${languageLabel} ${selectedQuestionIndex + 1}`} {/* Display the number of the chosen question */}
            </Typography>
            <MainQuestionHtmlShow content={selectedQuestion.question} type="question" />

            {multiChoiceOptions &&
              renderMultipleChoiceOptions(
                multiChoiceOptions,
                correctAnswer,
                selectedOption,
                showFeedback,
                selectedQuestion
              )}

            {showAnswer && (
              <>
                <Divider sx={{ marginY: 2 }} /> {/* Line between the question and the answer */}
                <MainQuestionHtmlShow content={selectedQuestion.answer} type="answer" />
              </>
            )}
          </Box>
        )}
      </Box>
      {/* <Box sx={{ position: 'fixed', bottom: 0, left: 0, width: '100%', display: 'flex', justifyContent: 'space-between', padding: '16px', backgroundColor: theme.palette.background.paper, zIndex: 2000 }}> */}
      <Box sx={{ position: 'fixed', bottom: 0, left: 0, width: '60%', display: 'flex', justifyContent: 'space-between', gap: 2, backgroundColor: theme.palette.background.paper, zIndex: 2000 }}>
        {/* Left Section: Navigation */}
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Tooltip title="Previous Set of Questions">
            <IconButton onClick={handlePerviousSetOfQuestions} disabled={disablePrevious}>
              <DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Previous Question">
            <IconButton onClick={handlePreviousQuestion} disabled={selectedQuestionIndex === 0}>
              <NavigateBeforeIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Next Question">
            <IconButton onClick={handleNextQuestion} disabled={selectedQuestionIndex === sortedAnswerData.length - 1}>
              <NavigateNextIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Next Set of Questions">
            <IconButton onClick={handleNextSetOfQuestions} disabled={disableNext}>
              <DoubleArrowIcon />
            </IconButton>
          </Tooltip>
        </Box>

        {/* Middle Section: Video Controls */}
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Tooltip title={showAnswer ? "Hide Answer" : "Show Answer"}>
            <IconButton onClick={() => { handleShowAnswer(selectedQuestion) }}>
              {showAnswer ? <UnpublishedIcon /> : < CheckCircleIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title={showMovie ? "Hide Movie" : "Show Movie"}>
            <IconButton onClick={handleToggleMovie}>
              {showMovie ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Back to Video">
            <IconButton onClick={onBackToVideo}>
              <VideoLibraryIcon />
            </IconButton>
          </Tooltip>
        </Box>

        {/* Right Section: Reporting and Feedback */}
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Tooltip title="Solved">
            <IconButton onClick={handleSolvedClick} sx={{ color: solvedColor }}>
              <ThumbUpAltIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Didn't Solve">
            <IconButton onClick={handleNotSolvedClick} sx={{ color: notSolvedColor }}>
              <ThumbDownAltIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Report a Problem">
            <IconButton onClick={handleReportClick} sx={{ color: reportColor }}>
              <ReportProblemIcon />
            </IconButton>
          </Tooltip>
          <Menu anchorEl={solvedAnchorEl} open={Boolean(solvedAnchorEl)} onClose={handleSolvedClose}>
            <MenuItem onClick={() => handleSolvedSelect('easy')}>Easy</MenuItem>
            <MenuItem onClick={() => handleSolvedSelect('medium')}>Medium</MenuItem>
            <MenuItem onClick={() => handleSolvedSelect('hard')}>Hard</MenuItem>
          </Menu>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleReportClose}>
            <MenuItem onClick={() => handleReportSelect("question isn't good")}>Question isn't good</MenuItem>
            <MenuItem onClick={() => handleReportSelect('answer not good')}>Answer not good</MenuItem>
            <MenuItem onClick={() => handleReportSelect('there is a typo')}>There is a typo</MenuItem>
            <MenuItem onClick={() => handleReportSelect('there is no problem')}>There is no problem</MenuItem>
          </Menu>
        </Box>
      </Box>
      {
        showMovie && (

          <VideoBoxInteract index={videoData} onClose={handleToggleMovie} y={-50} x={1000} widthBox={500} heightBox={560} />

        )
      }
    </Box >
  );
};

export default MainQuestionComponent;
