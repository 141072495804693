import React, { useState, useEffect, useMemo } from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tabs,
    Tab,
    Card,
    IconButton,
    Collapse,
} from "@mui/material";
import { getAllTable } from "../../../../api/serverApis/analayzeContentApi";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

interface Data {
    articleId: string;
    topicNumber: string;
    sectionNumber: string;
    audioFileName: string;
    sectionType: string;
    importance: string;
    sectionSummary: string;
    requiredFor: string;
    requiredTo: string;
    requiredFrom: string;
    sectionText: string;
    startTime: string;
    endTime: string;
    title: string;
    mainCategory: string;
    subCategory1: string;
    subCategory2: string;
    notClear: string;
    sectionSegment: string;
}

const isHebrewOrArabic = (text: string) =>
    /[\u0590-\u05FF\uFB1D-\uFB4F\uFB50-\uFDFF\u0600-\u06FF]/.test(text);

const AnalyzeContentComponent: React.FC = () => {
    const [data, setData] = useState<Data[]>([]);
    const [activeTab, setActiveTab] = useState(0);
    const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getAllTable();
                setData(result.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const toggleRowExpansion = (articleId: string) => {
        setExpandedRows((prev) => ({
            ...prev,
            [articleId]: !prev[articleId],
        }));
    };

    const filteredPerformTaskData = useMemo(
        () => data.filter((row) => row.sectionType === "performTask"),
        [data]
    );

    const filteredGeneralConversationData = useMemo(
        () => data.filter((row) => row.sectionType === "generalConversation"),
        [data]
    );

    const getRowStyle = (importance: string, index: number) => {
        const shades = {
            low: ["#d4edda", "#c8e6c9"], // Green shades
            medium: ["#ffeeba", "#ffdd99"], // Orange shades
            high: ["#f8d7da", "#f5b5b8"], // Red shades
        };

        const shadeIndex = index % 2; // Alternate between two shades
        switch (importance.toLowerCase()) {
            case "low":
                return { backgroundColor: shades.low[shadeIndex] };
            case "medium":
                return { backgroundColor: shades.medium[shadeIndex] };
            case "high":
                return { backgroundColor: shades.high[shadeIndex] };
            default:
                return {};
        }
    };

    return (
        <Box sx={{ p: 3, direction: "rtl" }}>
            <Card sx={{ p: 2, mb: 3 }}>
                <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth">
                    <Tab label="משימות" />
                    <Tab label="שיחות" />
                </Tabs>
            </Card>

            {activeTab === 0 && (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Expand</TableCell>
                                <TableCell>הקלטה</TableCell>
                                <TableCell>נושא המשימה</TableCell>
                                <TableCell>סיכום המשימה</TableCell>
                                <TableCell>למתי</TableCell>
                                <TableCell>למי</TableCell>
                                <TableCell>ממי</TableCell>
                                <TableCell>חשיבות המשימה</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredPerformTaskData.map((row, index) => (
                                <React.Fragment key={row.articleId}>
                                    <TableRow
                                        style={{
                                            ...getRowStyle(row.importance, index),
                                            borderBottom: "2px solid black",
                                        }}
                                    >
                                        <TableCell>
                                            <IconButton onClick={() => toggleRowExpansion(row.articleId)}>
                                                {expandedRows[row.articleId] ? (
                                                    <ExpandLessIcon />
                                                ) : (
                                                    <ExpandMoreIcon />
                                                )}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                direction: isHebrewOrArabic(row.audioFileName)
                                                    ? "rtl"
                                                    : "ltr",
                                            }}
                                        >
                                            {row.audioFileName}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                direction: isHebrewOrArabic(row.sectionSegment)
                                                    ? "rtl"
                                                    : "ltr",
                                            }}
                                        >
                                            {row.sectionSegment}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                direction: isHebrewOrArabic(row.sectionSummary)
                                                    ? "rtl"
                                                    : "ltr",
                                            }}
                                        >
                                            {row.sectionSummary}
                                        </TableCell>
                                        <TableCell>{row.requiredFor}</TableCell>
                                        <TableCell>{row.requiredTo}</TableCell>
                                        <TableCell>{row.requiredFrom}</TableCell>
                                        <TableCell>{row.importance}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            colSpan={8}
                                            style={{ padding: 0, border: 0 }}
                                        >
                                            <Collapse
                                                in={expandedRows[row.articleId] || false}
                                                timeout="auto"
                                                unmountOnExit
                                            >
                                                <Box sx={{ margin: 2 }}>
                                                    <Table size="small">
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell>Start Time</TableCell>
                                                                <TableCell>{row.startTime}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>End Time</TableCell>
                                                                <TableCell>{row.endTime}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Title</TableCell>
                                                                <TableCell>{row.title}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Main Category</TableCell>
                                                                <TableCell>{row.mainCategory}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Sub Category 1</TableCell>
                                                                <TableCell>{row.subCategory1}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Sub Category 2</TableCell>
                                                                <TableCell>{row.subCategory2}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>original Text</TableCell>
                                                                <TableCell>{row.sectionText}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {activeTab === 1 && (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Expand</TableCell>
                                <TableCell>הקלטה</TableCell>
                                <TableCell>נושא השיחה</TableCell>
                                <TableCell>סיכום השיחה</TableCell>
                                <TableCell>חשיבות השיחה</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredGeneralConversationData.map((row, index) => (
                                <React.Fragment key={row.articleId}>
                                    <TableRow
                                        style={{
                                            ...getRowStyle(row.importance, index),
                                            borderBottom: "2px solid black",
                                        }}
                                    >
                                        <TableCell>
                                            <IconButton onClick={() => toggleRowExpansion(row.articleId)}>
                                                {expandedRows[row.articleId] ? (
                                                    <ExpandLessIcon />
                                                ) : (
                                                    <ExpandMoreIcon />
                                                )}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                direction: isHebrewOrArabic(row.audioFileName)
                                                    ? "rtl"
                                                    : "ltr",
                                            }}
                                        >
                                            {row.audioFileName}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                direction: isHebrewOrArabic(row.sectionSegment)
                                                    ? "rtl"
                                                    : "ltr",
                                            }}
                                        >
                                            {row.sectionSegment}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                direction: isHebrewOrArabic(row.sectionSummary)
                                                    ? "rtl"
                                                    : "ltr",
                                            }}
                                        >
                                            {row.sectionSummary}
                                        </TableCell>
                                        <TableCell>{row.importance}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            colSpan={5}
                                            style={{ padding: 0, border: 0 }}
                                        >
                                            <Collapse
                                                in={expandedRows[row.articleId] || false}
                                                timeout="auto"
                                                unmountOnExit
                                            >
                                                <Box sx={{ margin: 2 }}>
                                                    <Table size="small">
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell>Start Time</TableCell>
                                                                <TableCell>{row.startTime}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>End Time</TableCell>
                                                                <TableCell>{row.endTime}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Not Clear</TableCell>
                                                                <TableCell>{row.notClear}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>original Text</TableCell>
                                                                <TableCell>{row.sectionText}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
};

export default AnalyzeContentComponent;
