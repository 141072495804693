import React, { useState, useEffect } from 'react';
import { Box, AppBar, Toolbar, Button, Drawer, useTheme, Typography, Divider, Paper } from '@mui/material';
import MainVideo from './MainVideo';
import MainSearch from './MainSearch';
import { useNavigate } from 'react-router-dom';
//import TableOContentComponent from './TableOfContentComponent';
import TableOfContentComponent from './TableOfContentComponent/TableOfContentComponent';
import SearchBox from '../SearchBox';
import MenuIcon from '@mui/icons-material/Menu';
import DrawerComponent from './DrawerComponent';
import BreadcrumbsComponent from './BreadcrumbsComponent';
import PlaylistComponent from './PlaylistComponent';
import SolverSearch from './SolverSearch';
import QuizComponent from './QuizComponent';
import ListAltIcon from '@mui/icons-material/ListAlt';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BuildIcon from '@mui/icons-material/Build';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import MainSearchFullScreen from './MainSearchFullScreen/MainSearchFullScreen';
import { VideoProgressProvider } from '../videoPlayer/VideoProgressContext';
import MainQuestionComponent from './MainQuestionComponent';
import { setSelectedVideoDetails } from '../../../features/coursesSlice';
import { RootState, AppDispatch } from '../../../store';
import { fetchCombinationSearch, getSearchByCourseId, getSearchByCourseIdAndAnswerDataId, getAllGlobalFAQData } from '../../../features/combinationSearchSlice';
import { fetchSolverSearch, getSolverSearchByCourseId, getSolverSearchByCourseIdAndAnswerDataId } from '../../../features/solverSearchSlice';
import { getQuizArticleIdAndTopicNumberSlice, getTopicsNumberByArticleIdSlice, setCurrentVideo, getQuizByArticleIdSlice } from '../../../features/quizSlice';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import LoadingDots from './LoadingDots';
import { use } from 'passport';
import { resetTheNumberAtTheBeginningOfAMonthAccordingToTheFirstDay } from '../../../api/serverApis/monitoringApi';
import FaqComponent from './FaqComponent';
import HelpIcon from '@mui/icons-material/Help';
import AnalyzeContentComponent from './analayzeConverstions/AnalyzeContentComponent';
interface VideoSearchLayoutProps {
  videoUrl: string;
  theme: any;
  searchData: any;
  videoData: any;
  globalFaqData: any;
  setIsLoading: any;
  isLoading: boolean;
  // solverSearchData?: any;
  playlistData?: any;
  handlePlaylistSelectClick?: any;
  getCourseId: () => string;
}
const VideoSearchLayout: React.FC<VideoSearchLayoutProps> = ({ searchData, globalFaqData, videoData, videoUrl, theme, setIsLoading, isLoading, playlistData, handlePlaylistSelectClick, getCourseId }) => {
  const [openDrawer, setOpenDrawer] = useState<string | null>('toc');
  const muiTheme = useTheme();
  const [quizStartTime, setQuizStartTime] = useState<string | undefined>(undefined);
  const [quizEndTime, setQuizEndTime] = useState<string | undefined>(undefined);
  const [movies, setMovies] = useState<any[]>([]);
  const [finishedSubVideos, setFinishedSubVideos] = useState<{ [key: string]: string[] }>({});
  const [selectedQuizData, setSelectedQuizData] = useState<any>(null);
  const [currentQuizIndex, setCurrentQuizIndex] = useState<number>(0);
  const [isQuizDataLoading, setIsQuizDataLoading] = useState(true);
  const dispatch = useDispatch<AppDispatch>();
  const quizData = useSelector((state: RootState) => state.quiz.quizData);
  const courseId = getCourseId();
  const [currentView, setCurrentView] = useState<string>('video');
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('disclaimerAccepted')) {
      navigate('/login'); // Redirect to login or an appropriate page if disclaimer is not accepted
    }
  }, []);
  ////***** Drawer ***** */
  const toggleDrawer = (drawerNameLocal: string) => {
    if (openDrawer === drawerNameLocal) {
      setOpenDrawer(null);
      return;
    }
    setOpenDrawer(drawerNameLocal);
  };
  useEffect(() => {
    if (openDrawer === 'faq') {
      dispatch(getAllGlobalFAQData({ userId: null, courseId, searchId: "first0001", answerDataId: null }));
    }
  }, [openDrawer, dispatch, courseId]);

  //**** handle Videos **** */
  const handleMoviesLoaded = (movies: any[]) => {
    setMovies(movies);
  };

  const handleVideoComplete = (movieId: string, subVideoId: string) => {
    setFinishedSubVideos(prevState => ({
      ...prevState,
      [movieId]: [...(prevState[movieId] || []), subVideoId]
    }));
  };

  const handleVideoButtonClick = (videoUrl: string, videoData: any) => {
    setCurrentView('video');
  };

  const handleBackToVideo = () => {
    setCurrentView('video');
  };

  //*** quiz or question*** */
  useEffect(() => {
    dispatch(getQuizArticleIdAndTopicNumberSlice({ articleId: videoData.movieId, topicNumber: videoData.id }));
  }, []);

  const handleGetQuizData = (movieId: string, topicNumber: string) => {
    setIsQuizDataLoading(true); // Start loading
    dispatch(getQuizArticleIdAndTopicNumberSlice({ articleId: movieId, topicNumber }))
      .finally(() => {
        setIsQuizDataLoading(false); // Stop loading once data is fetched
      });
  }
  const handleGetQuizByArticleIdData = (movieId: string) => {
    setIsQuizDataLoading(true); // Start loading
    dispatch(getQuizByArticleIdSlice({ articleId: movieId }))
      .finally(() => {
        setIsQuizDataLoading(false); // Stop loading once data is fetched
      });
  }
  const getTopicsNumberByArticleId = (articleId: string) => {
    dispatch(getTopicsNumberByArticleIdSlice({ articleId }));
  }

  const handleNextQuizSet = () => {
    if (currentQuizIndex < movies.length - 1) {
      const nextMovie = movies[currentQuizIndex + 1];
      setCurrentQuizIndex(currentQuizIndex + 1);
      handleGetQuizData(nextMovie.movieId, nextMovie.id);
      dispatch(setSelectedVideoDetails({ movieId: nextMovie.movieId, id: nextMovie.id, courseId: courseId ? courseId : "" }));
      dispatch(setCurrentVideo({ movieId: nextMovie.movieId, subVideoId: nextMovie.id }));
    }
  };

  const handlePreviousQuizSet = () => {
    if (currentQuizIndex > 0) {
      const prevMovie = movies[currentQuizIndex - 1];
      setCurrentQuizIndex(currentQuizIndex - 1);
      handleGetQuizData(prevMovie.movieId, prevMovie.id);
      dispatch(setSelectedVideoDetails({ movieId: prevMovie.movieId, id: prevMovie.id, courseId: courseId ? courseId : "" }));
      dispatch(setCurrentVideo({ movieId: prevMovie.movieId, subVideoId: prevMovie.id }));
    }
  };

  const handleQuizButtonClick = (quizDataForTableOfContent: any, isOnlyArticle?: boolean) => {
    if (quizDataForTableOfContent) {
      if (!isOnlyArticle) {
        handleGetQuizData(quizDataForTableOfContent.articleId, quizDataForTableOfContent.topicNumber);
      }
      else {
        handleGetQuizByArticleIdData(quizDataForTableOfContent.articleId);
      }
      setSelectedQuizData(quizData);
      setCurrentQuizIndex(quizDataForTableOfContent.topicNumber - 1);
      //setGetQuizFromPlayer(!getQuizFromPlayer);
      setCurrentView('question');
    }
  };


  //*******for ASK ********/ */
  const onSearch = async (searchTerm: string, time?: string, articleId?: string) => {
    try {
      setIsLoading(true);
      setOpenDrawer('ask');
      const sessionId = localStorage.getItem('courseSessionId');
      const searchLevels = JSON.parse(localStorage.getItem('searchLevels') || '[]');
      //const searchId = searchLevels[currentLevel];
      const searchId = "first0001";
      const courseIdLocal = getCourseId();
      const audioName = '';
      const onlySearch = '';
      const customer = '';
      const modelName = localStorage.getItem('modelName');
      const numberOfSearchResults = localStorage.getItem('numberOfSearchResults');
      const bestResultMethod = localStorage.getItem('bestResultMethod');
      const longContext = localStorage.getItem('longContext');
      const answerDataId = uuidv4();
      // dispatch(fetchCombinationSearch({ searchTerm, sessionId, courseId, audioName, onlySearch, searchId, time, articleId, customer, modelName, numberOfSearchResults, bestResultMethod, longContext, runType: "all" }));
      await resetTheNumberAtTheBeginningOfAMonthAccordingToTheFirstDay(courseIdLocal);
      dispatch(fetchCombinationSearch({ searchTerm, sessionId, courseId: courseIdLocal, audioName, onlySearch, searchId, time, articleId, customer, modelName, numberOfSearchResults, bestResultMethod, longContext, runType: "create", answerDataId }))
      dispatch(fetchCombinationSearch({ searchTerm, sessionId, courseId: courseIdLocal, audioName, onlySearch, searchId, time, articleId, customer, modelName, numberOfSearchResults, bestResultMethod, longContext, runType: "lines", answerDataId }));
      dispatch(fetchCombinationSearch({ searchTerm, sessionId, courseId: courseIdLocal, audioName, onlySearch, searchId, time, articleId, customer, modelName, numberOfSearchResults, bestResultMethod, longContext, runType: "answers", answerDataId }));
    }
    catch (e) {
      console.log(e);
    }
    finally {
      //setIsLoading(false);
    }
  };
  const resultOfOpeningAskQuestion = (searchId: string, answerDataId: string, userId?: string) => {
    dispatch(getSearchByCourseIdAndAnswerDataId({ userId: userId ? userId : null, courseId, searchId, answerDataId }));
  }
  const resultOfOpeningAskQuestionInTheFAQ = (searchId: string, answerDataId: string) => {
    dispatch(getAllGlobalFAQData({ userId: null, courseId, searchId, answerDataId }));
  }
  //*******for Solver ********/ */
  const onSolverSearch = (searchTerm: string, time?: string, articleId?: string) => {
    const sessionId = localStorage.getItem('courseSessionId');
    const searchLevels = JSON.parse(localStorage.getItem('searchLevels') || '[]');
    const searchId = "first0001";
    const courseIdLocal = getCourseId();
    const audioName = '';
    const onlySearch = '';
    const customer = '';
    const modelName = localStorage.getItem('modelNameSolver');
    const numberOfSearchResults = localStorage.getItem('numberOfSearchResultsSolver');
    const bestResultMethod = localStorage.getItem('bestResultMethodSolver');
    const longContext = localStorage.getItem('longContextSolver');
    dispatch(fetchSolverSearch({ searchTerm, sessionId, courseId: courseIdLocal, audioName, onlySearch, searchId, time, articleId, customer, modelName, numberOfSearchResults, bestResultMethod, longContext }));
  };
  const resultOfOpeningSolverQuestion = (searchId: string, answerDataId: string) => {
    dispatch(getSolverSearchByCourseIdAndAnswerDataId({ courseId, searchId, answerDataId }));
  }

  ////********* for mainSearchFullScreen */

  const handleMainSearchFullScreen = (screen: string) => {
    if (screen === 'search') {
      setCurrentView('search');
    };
    if (screen === 'faq') {
      setCurrentView('faq');
    };
    if (screen === 'analyze') {
      setCurrentView('analyze');
    }
    if (screen !== 'search' && screen !== 'faq' && screen !== 'analyze') {
      setCurrentView('video');
    }
  };

  const customScrollbar = {
    backgroundColor: theme.palette.background.paper,
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 6px ${muiTheme.palette.action.disabledBackground}`,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: muiTheme.palette.primary.main,
      outline: '1px solid slategrey',
    },
  };

  const renderMainComponent = () => {
    switch (currentView) {
      case 'video':
        return <MainVideo key={videoUrl} videoUrl={videoUrl} videoData={videoData} onSearch={onSearch} setIsLoading={setIsLoading} onTakeTest={handleQuizButtonClick} movieList={movies} onVideoComplete={handleVideoComplete} />;
      case 'search':
        return <MainSearchFullScreen data={searchData} theme={theme} />;
      case 'faq':
        return <MainSearchFullScreen data={globalFaqData} theme={theme} />;
      case 'analyze':
        return <AnalyzeContentComponent />;
      case 'question':
        if (isQuizDataLoading) {
          return <LoadingDots message={"Loading Question data"} />;
        }
        if (selectedQuizData) {
          return <MainQuestionComponent videoData={videoData} quizData={quizData} theme={theme} startTime={quizStartTime} endTime={quizEndTime} onBackToVideo={handleBackToVideo} onNextQuizSet={handleNextQuizSet} onPreviousQuizSet={handlePreviousQuizSet} disableNext={currentQuizIndex >= movies.length - 1} disablePrevious={currentQuizIndex <= 0} handleGetQuizData={handleGetQuizData} getQuizFromPlayer={true} />;
        }
        return (
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Typography variant="h6">Waiting for data...</Typography>
            <Button variant="contained" color="primary" onClick={handleQuizButtonClick} sx={{ mt: 2 }}>
              Retry
            </Button>
          </Box>
        );
      default:
        return null;
    }
  };

  return (

    <Box sx={{ display: 'flex', width: '100%', overflowY: 'hidden', backgroundColor: theme.palette.background.paper, minHeight: '95.7vh', }}>
      <Box sx={{ marginTop: '20px', width: openDrawer ? '60%' : '96%', ...customScrollbar, overflowY: 'auto' }}>
        {/* {selectedQuizData ? (
          <MainQuestionComponent videoData={videoData} quizData={quizData} theme={theme} startTime={quizStartTime} endTime={quizEndTime} onBackToVideo={handleBackToVideo} onNextQuizSet={handleNextQuizSet} onPreviousQuizSet={handlePreviousQuizSet} disableNext={currentQuizIndex >= movies.length - 1} disablePrevious={currentQuizIndex <= 0} handleGetQuizData={handleGetQuizData} getQuizFromPlayer={getQuizFromPlayer} />
        ) : (
          <MainVideo key={videoUrl} videoUrl={videoUrl} videoData={videoData} onSearch={onSearch} setIsLoading={setIsLoading} onTakeTest={handleQuizButtonClick} movieList={movies} onVideoComplete={handleVideoComplete} />
          // <MainSearchFullScreen data={searchData} theme={theme} />
        )} */}
        {renderMainComponent()}
      </Box>

      <DrawerComponent isOpen={openDrawer === 'toc'} toggleDrawer={() => toggleDrawer('toc')} title={"Table Of Content"} label={"TOC"} topPosition={'17%'} Icon={ListAltIcon}>
        <TableOfContentComponent courseId={courseId} key={courseId} videoData={videoData} onMoviesLoaded={handleMoviesLoaded} finishedSubVideos={finishedSubVideos} onQuizButtonClick={handleQuizButtonClick} onVideoButtonClick={handleVideoButtonClick} getTopicsNumberByArticleId={getTopicsNumberByArticleId} />
      </DrawerComponent>
      <DrawerComponent isOpen={openDrawer === 'ask'} toggleDrawer={() => toggleDrawer('ask')} title={"Ask Questions"} label={"ASK"} topPosition={'25%'} Icon={QuestionAnswerIcon}>
        <MainSearch courseId={courseId} currentView={currentView} handleMainSearchFullScreen={handleMainSearchFullScreen} data={searchData} theme={theme} resultOfOpeningAskQuestion={resultOfOpeningAskQuestion} />
      </DrawerComponent>
      {/* <DrawerComponent isOpen={openDrawer === 'quiz'} toggleDrawer={() => toggleDrawer('quiz')} title={"Quiz"} label={"QUIZ"} topPosition={'31%'} Icon={AssignmentIcon} >
        <QuizComponent quizData={quizData} theme={theme} startTime={quizStartTime} endTime={quizEndTime} />
      </DrawerComponent>
      <DrawerComponent isOpen={openDrawer === 'solver'} toggleDrawer={() => toggleDrawer('solver')} title={"Solver"} label={"SOLVER"} topPosition={'39%'} Icon={BuildIcon}>

        {solverSearchData && <SolverSearch data={solverSearchData} theme={theme} resultOfOpeningSolverQuestion={resultOfOpeningSolverQuestion} />}
      </DrawerComponent> */}
      <DrawerComponent isOpen={openDrawer === 'faq'} toggleDrawer={() => toggleDrawer('faq')} title={"FAQ"} label={"FAQ"} topPosition={'32%'} Icon={HelpIcon}>
        {globalFaqData && <FaqComponent currentView={currentView} handleMainSearchFullScreen={handleMainSearchFullScreen} data={globalFaqData} theme={theme} resultOfOpeningAskQuestion={resultOfOpeningAskQuestionInTheFAQ} />}
      </DrawerComponent>
      <DrawerComponent isOpen={openDrawer === 'playlist'} toggleDrawer={() => toggleDrawer('playlist')} title="Playlist" label="PLAYLIST" topPosition={'39%'} Icon={PlaylistPlayIcon}> {/*47% */}
        <PlaylistComponent data={playlistData} theme={theme} handlePlaylistSelectClick={handlePlaylistSelectClick} searchData={searchData} />
      </DrawerComponent>
      {/* Fixed Footer */}

      {/* {((openDrawer === 'ask' && !selectedQuizData) || (openDrawer === 'toc' && !selectedQuizData) || (openDrawer === 'playlist' && !selectedQuizData) || (openDrawer === 'question' && !selectedQuizData)) && <Box position="fixed" color="default" sx={{ border: '1px solid', top: 'auto', bottom: openDrawer ? 0 : 50, width: openDrawer ? '39.8%' : '100%', right: 0, zIndex: 1350, background: theme.palette.background.default, ...customScrollbar }}> */}
      <Box position="fixed" color="default"
        sx={{
          border: '0px solid',
          top: 'auto',
          bottom: openDrawer ? 0 : 0,
          width: openDrawer ? '34.5%' : '90%',
          right: openDrawer ? 65 : 90,
          zIndex: 1350,
          background: theme.palette.background.Paper,
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)', // Add shadow for a floating effect
          borderRadius: '8px', // Rounded corners
          transform: 'translateY(-10px)', // Slightly above its position
          transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth hover effect
          '&:hover': {
            boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.3)', // Stronger shadow on hover
            transform: 'translateY(-15px)', // Moves higher when hovered
          },
          opacity: 0.95, // Slight transparency
          backdropFilter: 'blur(10px)', // Background blur for a glassy effect
          ...customScrollbar,
        }}>
        <SearchBox onSearch={onSearch} isLoading={isLoading} placeholder="Ask" />
      </Box>
      {/* {(openDrawer === 'solver') && <Box position="fixed" color="default" sx={{ border: '1px solid', top: 'auto', bottom: 0, width: openDrawer ? '39.8%' : '100%', right: 0, zIndex: 1350, background: theme.palette.background.default, ...customScrollbar }}>
        <SearchBox onSearch={onSolverSearch} isLoading={isLoading} placeholder="Solver" />
      </Box>} */}
    </Box>

  );
};

export default VideoSearchLayout;