import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
    sendMessage,
    getMessagesForUser,
    getMessageWithAttachments,
    markMessageAsReadApi,
    getUnreadMessagesByRecipientId,
} from '../api/serverApis/messagingApi';

// Define the Message interface
interface Message {
    messageId: string;
    sender: string;
    recipient: string;
    subject: string;
    body: string;
    attachments?: File[];
    timestamp: Date;
    isRead: boolean;
}

// Define the state interface
interface MessagingState {
    messages: Message[];
    unreadCount: number;
    loading: boolean;
    error: string | null;
    currentMessage: Message | null;
}

// Initial state
const initialState: MessagingState = {
    messages: [],
    unreadCount: 0,
    loading: false,
    error: null,
    currentMessage: null,
};

// Async thunk to fetch messages for the user
export const fetchMessages = createAsyncThunk('messaging/fetchMessages', async () => {
    const response = await getMessagesForUser();
    return response.data;
});

// Async thunk to fetch unread messages count
export const fetchUnreadCount = createAsyncThunk('messaging/fetchUnreadCount', async () => {
    const response = await getUnreadMessagesByRecipientId();
    return response.data;
});

// Async thunk to fetch a single message with attachments
export const fetchMessageWithAttachments = createAsyncThunk('messaging/fetchMessageWithAttachments', async (messageId: string) => {
    const response = await getMessageWithAttachments(messageId);
    return response.data;
}
);


const handleState = (action: any) => {
    return (builder: any) => {
        builder
            .addCase(action.pending, (state: any) => {
                state.loading = true;
            })
            .addCase(action.fulfilled, (state: any, action: PayloadAction<any>) => {
                state.loading = false;
                if (action.type === 'messaging/fetchMessages/fulfilled') {
                    state.messages = action.payload;
                }
                if (action.type === 'messaging/fetchUnreadCount/fulfilled') {
                    state.unreadCount = action.payload;
                }
                if (action.type === 'messaging/fetchMessageWithAttachments/fulfilled') {
                    state.data = action.payload;
                }
            })
            .addCase(action.rejected, (state: any, action: any) => {
                state.loading = false;
                state.error = action.error.message;
            });
    };
}
const messagingSlice = createSlice({
    name: 'messaging',
    initialState,
    reducers: {
        resetCurrentMessage(state) {
            state.currentMessage = null;
        },
    },
    extraReducers: (builder) => {
        handleState(fetchMessages)(builder);
        handleState(fetchUnreadCount)(builder);
        handleState(fetchMessageWithAttachments)(builder);
    }
});

// Export actions and reducer
export const { resetCurrentMessage } = messagingSlice.actions;
export default messagingSlice.reducer;
