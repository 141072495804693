import React, { useState, useEffect, useRef } from 'react';
import { Tooltip, Modal, Typography, Box, useTheme, Paper, Stack, IconButton, Button, Dialog, DialogTitle } from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HtmlBoxInteract from './HtmlBoxInteract';

interface MainQuestionHtmlShowProps {
  content: string;
  type: 'question' | 'answer';
}

const MainQuestionHtmlShow: React.FC<MainQuestionHtmlShowProps> = ({ content, type }) => {
  const theme = useTheme();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeHeight, setIframeHeight] = useState<number>(0);

  useEffect(() => {
    if (iframeRef.current) {
      const iframeDoc = iframeRef.current.contentDocument;
      if (iframeDoc) {
        const doc = iframeRef.current.contentDocument;
        const iframe = iframeRef.current;
        const iframeDoc = iframe.contentDocument;
        if (doc) {
          doc.open();
          doc.write(content);
          doc.close();
        }
        const styleElement = iframeDoc?.createElement('style');
        if (styleElement) {
          styleElement.innerHTML = `
        body {
            margin: 0;
            padding: 0;
            font-family: Arial, sans-serif;
            line-height: 1.5;
            background-color: white;
            overflow: hidden;
          }
          .container {
            box-shadow: none !important;
          }
      `;
          iframeDoc?.head.appendChild(styleElement);
        }
      }
      if (iframeDoc) {
        const styleElement = iframeDoc?.createElement('style');
        styleElement.innerHTML = `body { background-color: white; margin: '20px';   }`;
        iframeDoc?.head.appendChild(styleElement);
      }
      handleIframeLoad();
    }
  }, [content]);

  const handleIframeLoad = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.style.height = iframe.contentWindow.document.documentElement.scrollHeight + 'px';
    }
  };
  const adjustIframeHeight = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      const height = iframe.contentWindow.document.documentElement.scrollHeight;
      setIframeHeight(height - 60); // Update the iframe height state
    }
  };
  return (
    <Box sx={{ width: '100%', height: `${iframeHeight}px`, transition: 'height 0.3s ease' }}>
      <iframe
        ref={iframeRef}
        style={{
          width: '100%',
          border: 'none',
          background: 'white',
        }}
        onLoad={adjustIframeHeight}
      />
    </Box>
  );
};

export default MainQuestionHtmlShow;
