import axiosInstance from "../interceptor";
import { CHATGPT_API_URL, GATEWAY_API_URL } from "../api";


const api = '/api/messagingService/api/v1/messagingRouter';

export const sendMessage = async (data: { recipientId: string[] | null; subject: string; body: string; isBroadcast?: boolean; attachments?: File[]; }) => {
    try {

        const sendData = {
            recipientId: data.recipientId ? data.recipientId.join(",") : "",
            subject: data.subject,
            body: data.body,
            isBroadcast: data.isBroadcast || false
        }
        return axiosInstance.post(`${GATEWAY_API_URL}${api}/sendMessage`, sendData);
    } catch (error) {
        console.error('Error in MessagingService.sendMessage:', error);
        throw new Error('MessagingService: [sendMessage]: ' + error);
    }
};

// Function to retrieve all messages for a user
export const getMessagesForUser = async () => {
    return axiosInstance.get(`${GATEWAY_API_URL}${api}/getMessagesForUser`);
};

// Function to retrieve a single message with its attachments
export const getMessageWithAttachments = async (messageId: string) => {
    return axiosInstance.get(`${GATEWAY_API_URL}${api}/getMessageWithAttachments/${messageId}`);
};

// Function to list all users (assumes there's an endpoint at /api/users)
export const getUsers = async () => {
    return axiosInstance.get(`${GATEWAY_API_URL}/api/users`);
};
export const getUnreadMessagesByRecipientId = async () => {
    return axiosInstance.get(`${GATEWAY_API_URL}${api}/unreadMessages`);
}
export const markMessageAsReadApi = async (messageId: string) => {
    try {
        return axiosInstance.get(`${GATEWAY_API_URL}${api}/markAsRead/${messageId}`);
    }
    catch (error) {
        console.error('Error in MessagingService.markMessageAsRead:', error);
        throw new Error('MessagingService: [markMessageAsRead]: ' + error);
    }
};
export const getAllMessagesBySenderIdApi = async () => {
    try {
        return axiosInstance.get(`${GATEWAY_API_URL}${api}/getAllMessagesBySenderId`);
    }
    catch (error) {
        console.error('Error in MessagingService.getAllMessagesBySenderId:', error);
        throw new Error('MessagingService: [getAllMessagesBySenderId]: ' + error);
    }
}