import React, { useState, useRef } from 'react';
import { Box, Typography, Grid, IconButton, Tooltip, TextField } from '@mui/material';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import StopIcon from '@mui/icons-material/Stop';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { uploadFile } from '../../api/serverApis/filesApi';

interface VideoRecordingProps {
    onDelete: (index: number) => void;
}

const VideoRecordingComponent: React.FC<VideoRecordingProps> = ({ onDelete }) => {
    const [recordingVideo, setRecordingVideo] = useState(false);
    const [videoRecordings, setVideoRecordings] = useState<
        { url: string; blob: Blob; name: string }[]
    >([]);
    const [fileNames, setFileNames] = useState<{ [key: number]: string }>({});
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const chunksRef = useRef<Blob[]>([]);

    const handleVideoRecord = async () => {
        if (!recordingVideo) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                mediaRecorderRef.current = new MediaRecorder(stream);
                chunksRef.current = [];
                mediaRecorderRef.current.ondataavailable = (e) => {
                    chunksRef.current.push(e.data);
                };
                mediaRecorderRef.current.onstop = () => {
                    const blob = new Blob(chunksRef.current, { type: 'video/mp4' });
                    const url = URL.createObjectURL(blob);
                    setVideoRecordings((prev) => [
                        ...prev,
                        { url, blob, name: '' }
                    ]);
                };
                mediaRecorderRef.current.start();
                setRecordingVideo(true);
            } catch (error) {
                console.error('Error starting video recording:', error);
            }
        } else {
            mediaRecorderRef.current?.stop();
            setRecordingVideo(false);
        }
    };

    const handleFileNameChange = (index: number, name: string) => {
        setFileNames((prev) => ({ ...prev, [index]: name }));
        setVideoRecordings((prev) =>
            prev.map((recording, i) =>
                i === index ? { ...recording, name } : recording
            )
        );
    };

    const handleUpload = async (index: number) => {
        const recording = videoRecordings[index];
        const fileName = fileNames[index] || `VideoRecording_${index + 1}.mp4`;

        const file = new File([recording.blob], fileName, { type: 'video/mp4' });

        try {
            const response = await uploadFile(file); // Call the API
            console.log('Uploaded successfully:', response.data);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    return (
        <Box>
            <Typography variant="h6">Video Recordings</Typography>
            <Tooltip title="Record Video">
                <IconButton
                    color={recordingVideo ? 'secondary' : 'primary'}
                    onClick={handleVideoRecord}
                >
                    {recordingVideo ? <StopIcon /> : <VideoCameraFrontIcon />}
                </IconButton>
            </Tooltip>
            <Grid container spacing={2}>
                {videoRecordings.map((recording, index) => (
                    <Grid item key={index} xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 2,
                                p: 2,
                                border: '1px solid #ccc',
                                borderRadius: '8px',
                            }}
                        >
                            <video controls src={recording.url} style={{ flex: 2 }} width="300" />
                            <TextField
                                label="File Name"
                                variant="outlined"
                                size="small"
                                value={recording.name || fileNames[index] || ''}
                                onChange={(e) => handleFileNameChange(index, e.target.value)}
                                sx={{ flex: 1 }}
                            />
                            <Tooltip title="Upload">
                                <IconButton color="primary" onClick={() => handleUpload(index)}>
                                    <CloudUploadIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton
                                    color="error"
                                    onClick={() => onDelete(index)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default VideoRecordingComponent;
