import React, { useState, useMemo } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LinkIcon from '@mui/icons-material/Link';
import AudioRecordingComponent from '../components/uploads/AudioRecordingComponent';
import VideoRecordingComponent from '../components/uploads/VideoRecordingComponent';
import ScreenRecordingComponent from '../components/uploads/ScreenRecordingComponent';
import FileUploadComponent from '../components/uploads/FileUploadComponent';
import VideoLinkComponent from '../components/uploads/VideoLinkComponent';
import Header from '../components/combinationSearchComponents/Header';
import getTheme from '../components/combinationSearchComponents/configuration/Themes';

const UploadPage: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [themeMode, setThemeMode] = useState<'light' | 'dark'>('light');
    const theme = useMemo(() => getTheme(themeMode), [themeMode]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };
    const toggleTheme = () => {
        setThemeMode(prevMode => prevMode === 'light' ? 'dark' : 'light');
    };

    return (
        <Box>
            {/* Header */}
            <Box sx={{ position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 1200, backgroundColor: theme.palette.background.paper }}>
                <Header header={"Xarcam Upload"} themeMode={themeMode} toggleTheme={toggleTheme} title={""} />
            </Box>

            {/* Main Layout */}
            <Box sx={{ display: 'flex', height: '100vh', marginTop: '56px' }}>
                {/* Sidebar Menu */}
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    orientation="vertical"
                    variant="scrollable"
                    sx={{
                        borderRight: 1,
                        borderColor: 'divider',
                        minWidth: '200px',

                        pt: 2,
                        '& .MuiTab-root': {
                            justifyContent: 'flex-start', // Align content to the left
                            gap: 1, // Add spacing between the icon and text
                            textAlign: 'left', // Align text to the left
                            paddingLeft: '16px', // Add padding to align content nicely
                        },
                        '& .MuiTabs-indicator': {
                            left: 0, // Move the indicator to the left
                        },
                    }}
                >
                    <Tab label="Audio Recording" icon={<MicIcon />} iconPosition="start" />
                    <Tab label="Video Recording" icon={<VideoCameraFrontIcon />} iconPosition="start" />
                    <Tab label="Screen Recording" icon={<ScreenShareIcon />} iconPosition="start" />
                    <Tab label="File Uploads" icon={<CloudUploadIcon />} iconPosition="start" />
                    <Tab label="Video Links" icon={<LinkIcon />} iconPosition="start" />
                </Tabs>

                {/* Content Area */}
                <Box sx={{ flex: 1, p: 3 }}>
                    {selectedTab === 0 && <AudioRecordingComponent onDelete={(index) => console.log('Delete Audio', index)} />}
                    {selectedTab === 1 && <VideoRecordingComponent onDelete={(index) => console.log('Delete Video', index)} />}
                    {selectedTab === 2 && <ScreenRecordingComponent onDelete={(index) => console.log('Delete Screen', index)} />}
                    {selectedTab === 3 && <FileUploadComponent onDelete={(index) => console.log('Delete File', index)} />}
                    {selectedTab === 4 && <VideoLinkComponent />}
                </Box>
            </Box>
        </Box>
    );
};

export default UploadPage;
