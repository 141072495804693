import React, { useState, useRef } from 'react';
import { Semantic, Index } from '../configuration/Interface';
import { Card, CardMedia, CardContent, Typography, Box, useTheme, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, IconButton, Modal, Tooltip } from '@mui/material';
import logo1 from '../../images/logo1.png'; // Ensure this path is correct
import VideoBoxInteract from '../VideoBoxInteract';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import UndoIcon from '@mui/icons-material/Undo';
import { handleTimes } from '../../searchComponents/UtilityFunctions';
import { getSearchByCourseId, fetchMovieDeleteMovie } from '../../../features/combinationSearchSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../store';
import DeleteIcon from '@mui/icons-material/Delete';
import { index } from 'd3';
interface IndexProps {
  data: Semantic | Index;
  isOpen: boolean;
  question: string;
  handlePlaylistSelectClick?: any;
  //solverSearchData: any;
  searchData: any;
}

const VideoIndexPlaylist: React.FC<IndexProps> = ({ data, isOpen, question, handlePlaylistSelectClick, searchData }) => {
  const theme = useTheme();
  const [openVideoInteract, setOpenVideoInteract] = useState(false);
  const [hover, setHover] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const videoCardRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();

  const handleImageClick = () => {
    handlePlaylistSelectClick(data.movieLink, searchData, data, data.title, true);
  };

  const handleCloseVideoInteract = () => {
    setOpenVideoInteract(false);
  };
  const handleTimesLocally = () => {
    if (!data.pageNumber || data.pageNumber === '-1') {
      const { startTime, endTime, frameTime } = data;
      const sTime = startTime ? startTime.toString() : '';
      const eTime = endTime ? endTime.toString() : '';
      const fTime = frameTime ? frameTime.toString() : '';
      return handleTimes(sTime, eTime, fTime);
    }
    if (data.pageNumber || data.pageNumber !== '-1') {
      return `Page: ${data.pageNumber}`;
    }
  }

  const handleMovieDelete = () => {
    // Close confirmation dialog first
    setDeleteConfirmOpen(false);
    // Dispatch your delete action here
    dispatch(fetchMovieDeleteMovie({ courseId: data.courseId, searchId: data.searchIdMetaData, searchIdMovie: data.searchId, articleId: data.articleId }));
  };
  const getIconForFileType = () => {
    const isPDF = data.movieLink.indexOf('.pdf') != -1;
    return isPDF ? <PictureAsPdfIcon fontSize="large" /> : <PlayCircleOutlineIcon fontSize="large" />;
  };

  return (
    <>

      <Card
        ref={videoCardRef}
        sx={{
          display: isOpen ? 'none' : 'flex',
          alignItems: 'center',
          maxWidth: "100%",
          maxHeight: '100%',
          mb: 2,
          height: 'auto',
          cursor: 'pointer',
          backgroundColor: theme.palette.background.default,
          margin: '1rem',
          borderRadius: '1rem',

        }}
      >
        <Box sx={{ paddingLeft: '1rem', margin: '1rem' }}>
          <CardMedia
            component="img"
            sx={{ width: 90, height: 60, objectFit: 'cover' }}
            image={data.pageImage || '/path/to/default/image.png'}
            alt="Video thumbnail"
            onClick={handleImageClick}
          />
          {(
            <IconButton
              sx={{

                position: 'realative',
                marginLeft: '1rem',
                marginTop: '-5rem',
                color: theme.palette.primary.main,
                zIndex: 9,
              }}
              onClick={handleImageClick}
            >
              {getIconForFileType()}
            </IconButton>
          )}

          <Typography variant="subtitle2" sx={{ mt: 1, fontSize: '0.7rem', width: '6rem', marginTop: '-1.5rem' }}>
            {handleTimesLocally()}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center' }} >
          <Card>
            <CardContent >
              <Box sx={{ display: 'flex' }}>
                <Paper elevation={3} sx={{
                  padding: theme.spacing(2),
                  backgroundColor: theme.palette.background.paper,
                  border: `2px solid ${theme.palette.primary.light}`,
                  borderRadius: theme.shape.borderRadius,

                }}>
                  <Typography sx={{ textAlign: 'left', fontSize: '0.6rem', marginBottom: '2%' }}>Video: {data.title}</Typography>
                  <Typography variant="body1" sx={{ fontSize: '0.8rem', color: "gray" }}>
                    <Typography>Index Search Found The Words:</Typography>
                    <Typography variant="body1" sx={{ fontSize: '0.8rem', color: 'black' }}> {question}</Typography>
                  </Typography>
                  <Tooltip title="Delete the movie">
                    <IconButton
                      sx={{
                        position: 'relative',
                        top: theme.spacing(4),
                        right: theme.spacing(1),
                        color: theme.palette.info.main,
                        height: '1px',
                      }}
                      onClick={() => setDeleteConfirmOpen(true)}
                    >
                      <DeleteIcon sx={{ fontSize: '20px', }} />
                    </IconButton>
                  </Tooltip>
                  <Dialog
                    open={deleteConfirmOpen}
                    onClose={() => setDeleteConfirmOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Confirm Deletion"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this movie?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
                      <Button onClick={handleMovieDelete} autoFocus>
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Paper>
              </Box>
              {/* <Typography padding='1rem' variant="body2" color="text.secondary">Description: {data.description}</Typography> */}
            </CardContent>
          </Card>
        </Box >

      </Card >
      <Modal
        open={openVideoInteract}
        onClose={handleCloseVideoInteract}
        sx={{
          // This centers the modal, adjust positioning as needed
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <VideoBoxInteract index={data} onClose={handleCloseVideoInteract} />
      </Modal>
    </>
  );
};

export default VideoIndexPlaylist;