import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, Grid, IconButton, Tooltip, TextField } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { uploadFile } from '../../api/serverApis/filesApi';

interface AudioRecordingProps {
    onDelete: (index: number) => void;
}

const AudioRecordingComponent: React.FC<AudioRecordingProps> = ({ onDelete }) => {
    const [recordingAudio, setRecordingAudio] = useState(false);
    const [audioRecordings, setAudioRecordings] = useState<{ url: string; name: string; blob: Blob | null }[]>([]);
    const [fileNames, setFileNames] = useState<{ [key: number]: string }>({});
    const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
    const [analyserNode, setAnalyserNode] = useState<AnalyserNode | null>(null);
    const [audioData, setAudioData] = useState<Uint8Array | null>(null);

    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const chunksRef = useRef<Blob[]>([]);
    const animationRef = useRef<number | null>(null);

    useEffect(() => {
        const checkMicrophonePermissions = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                console.log('Microphone access granted:', stream);
                stream.getTracks().forEach((track) => track.stop());
            } catch (error) {
                console.error('Microphone access denied:', error);
            }
        };

        checkMicrophonePermissions();
    }, []);

    const getFormattedFileName = (): string => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        const milliseconds = String(now.getMilliseconds()).padStart(3, '0').slice(0, 2);

        return `audioRecord-${year}-${month}-${day}-${hours}-${minutes}-${seconds}-${milliseconds}.mp3`;
    };
    const handleDelete = (index: number) => {
        setAudioRecordings((prev) => prev.filter((_, i) => i !== index)); // Update the state to remove the item
        setFileNames((prev) => {
            const newFileNames = { ...prev };
            delete newFileNames[index];
            return newFileNames;
        }); // Remove the corresponding file name

        if (onDelete) {
            onDelete(index); // Call the onDelete callback if needed
        }
    };
    const handleAudioRecord = async () => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            alert('Your browser does not support audio recording.');
            return;
        }

        if (!recordingAudio) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

                const audioCtx = new (window.AudioContext || (window as any).webkitAudioContext)();
                const analyser = audioCtx.createAnalyser();
                const source = audioCtx.createMediaStreamSource(stream);

                analyser.fftSize = 256;
                const bufferLength = analyser.frequencyBinCount;
                const dataArray = new Uint8Array(bufferLength);

                source.connect(analyser);
                setAudioContext(audioCtx);
                setAnalyserNode(analyser);
                setAudioData(dataArray);

                mediaRecorderRef.current = new MediaRecorder(stream);
                chunksRef.current = [];
                mediaRecorderRef.current.ondataavailable = (e) => {
                    chunksRef.current.push(e.data);
                };
                mediaRecorderRef.current.onstop = async () => {
                    const blob = new Blob(chunksRef.current, { type: 'audio/ogg; codecs=opus' });
                    const url = URL.createObjectURL(blob);
                    const defaultName = getFormattedFileName();

                    const newRecording = { url, name: defaultName, blob };
                    setAudioRecordings((prev) => [...prev, newRecording]);

                    // Upload automatically
                    await handleUpload(audioRecordings.length, newRecording);

                    if (audioCtx) {
                        audioCtx.close();
                        setAudioContext(null);
                        setAnalyserNode(null);
                    }
                };

                mediaRecorderRef.current.start();
                setRecordingAudio(true);
            } catch (error) {
                console.error('Error starting audio recording:', error);
                alert('Failed to start audio recording. Please check your microphone permissions.');
            }
        } else {
            mediaRecorderRef.current?.stop();
            setRecordingAudio(false);
        }
    };

    const drawVisualizer = () => {
        if (!analyserNode || !audioData) return;

        const canvas = document.getElementById('audioVisualizer') as HTMLCanvasElement;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const WIDTH = canvas.width;
        const HEIGHT = canvas.height;

        analyserNode.getByteFrequencyData(audioData);

        ctx.clearRect(0, 0, WIDTH, HEIGHT);

        const barWidth = (WIDTH / audioData.length) * 2.5;
        let barHeight;
        let x = 0;

        for (let i = 0; i < audioData.length; i++) {
            barHeight = audioData[i] / 2;
            ctx.fillStyle = `rgb(${barHeight + 100},50,50)`;
            ctx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight);
            x += barWidth + 1;
        }

        animationRef.current = requestAnimationFrame(drawVisualizer);
    };

    useEffect(() => {
        if (recordingAudio) {
            drawVisualizer();
        } else if (animationRef.current) {
            cancelAnimationFrame(animationRef.current);
            animationRef.current = null;
        }
        return () => {
            if (animationRef.current) cancelAnimationFrame(animationRef.current);
        };
    }, [recordingAudio, analyserNode, audioData]);

    const handleFileNameChange = (index: number, name: string) => {
        setFileNames((prev) => ({ ...prev, [index]: name }));
        setAudioRecordings((prev) =>
            prev.map((recording, i) => (i === index ? { ...recording, name } : recording))
        );
    };

    const handleUpload = async (index: number, recording?: { url: string; name: string; blob: Blob | null }) => {
        const targetRecording = recording || audioRecordings[index];
        if (targetRecording.blob) {
            const file = new File(
                [targetRecording.blob],
                fileNames[index] || targetRecording.name,
                { type: 'audio/ogg' }
            );

            try {
                const response = await uploadFile(file);
                console.log('Uploaded successfully:', response.data);
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };

    return (
        <Box>
            <Typography variant="h6">Audio Recordings</Typography>

            {recordingAudio && (
                <Box sx={{ mb: 2 }}>
                    <canvas
                        id="audioVisualizer"
                        width="400"
                        height="100"
                        style={{
                            display: 'block',
                            backgroundColor: '#eee',
                            margin: '0 auto',
                        }}
                    ></canvas>
                </Box>
            )}

            <Tooltip title="Record Audio">
                <IconButton
                    color={recordingAudio ? 'secondary' : 'primary'}
                    onClick={handleAudioRecord}
                >
                    {recordingAudio ? <StopIcon /> : <MicIcon />}
                </IconButton>
            </Tooltip>

            <Grid container spacing={2}>
                {audioRecordings.map((recording, index) => (
                    <Grid item key={index} xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 2,
                                p: 2,
                                border: '1px solid #ccc',
                                borderRadius: '8px',
                            }}
                        >
                            <audio controls src={recording.url} style={{ flex: 2 }} />
                            <TextField
                                label="File Name"
                                variant="outlined"
                                size="small"
                                value={fileNames[index] || recording.name || ''}
                                onChange={(e) => handleFileNameChange(index, e.target.value)}
                                sx={{ flex: 1 }}
                            />
                            <Tooltip title="Upload">
                                <IconButton color="primary" onClick={() => handleUpload(index)}>
                                    <CloudUploadIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton
                                    color="error"
                                    onClick={() => handleDelete(index)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default AudioRecordingComponent;
