import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store';
import 'storm-react-diagrams/dist/style.min.css';
import App from './App';


// Version check logic
// const checkVersion = async () => {
//   try {
//     const response = await fetch('/version.json');
//     const { version: newVersion } = await response.json();

//     if (newVersion !== process.env.REACT_APP_VERSION) {
//       console.log('New version available. Reloading...');
//       window.location.reload();
//     }
//   } catch (error) {
//     console.error('Failed to fetch version:', error);
//   }
// };

// Check version every minute
// setInterval(checkVersion, 160000);

const root = createRoot(document.getElementById('root')!);
root.render(
  //<React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  //</React.StrictMode>,

);


