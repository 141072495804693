import React from 'react';
import { Drawer, useTheme, Typography, Box, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

interface DrawerComponentProps {
  isOpen: boolean;
  toggleDrawer: () => void;
  children: React.ReactNode;
  title?: string;
  label?: string;
  topPosition?: string;
  color?: string;
  Icon: React.ElementType;
}

const DrawerComponent: React.FC<DrawerComponentProps> = ({ isOpen, toggleDrawer, children, title, label, topPosition, color, Icon }) => {
  const theme = useTheme();
  return (
    <Box>
      <IconButton
        onClick={toggleDrawer}
        sx={{
          position: 'fixed',
          right: 0,
          top: topPosition,
          zIndex: 1300,
          width: '3.5rem',
          backgroundColor: isOpen ? theme.palette.primary.main : 'transparent',
          color: isOpen ? theme.palette.primary.contrastText : color,
          '&:hover': {
            backgroundColor: isOpen ? theme.palette.primary.dark : theme.palette.action.hover,
          },
          flexDirection: 'column',
          borderRadius: 0, // Remove circular outline
        }}
      >
        <Icon />
        <Typography
          variant="caption"
          sx={{ mt: 0.5, fontSize: '0.5rem', color: isOpen ? theme.palette.primary.contrastText : color }}
        >
          {label}
        </Typography>
      </IconButton>
      <Drawer
        variant="persistent"
        anchor="right"
        open={isOpen}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            width: '39.8%',
            marginTop: '3.8%',
            zIndex: 1100,
            '&::-webkit-scrollbar': {
              width: '0em',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: `inset 0 0 0px ${theme.palette.action.disabledBackground}`,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              outline: '0px solid slategrey',
            },
          },
        }}
      >
        {title && (
          <Box sx={{
            height: '63.5px', position: 'fixed', width: '42%', padding: theme.spacing(1), borderBottom: `1px solid ${theme.palette.divider}`, zIndex: 50, backgroundColor: theme.palette.background.paper, display: 'flex', alignItems: 'center', // Center vertically
            justifyContent: 'center',
          }}>
            <Typography sx={{ textAlign: 'center', justifyContent: 'center', fontSize: '20px' }}>{title}</Typography>
          </Box>
        )}
        <Box sx={{ marginTop: '8%', marginRight: '2.5%' }}>
          {children}
        </Box>
      </Drawer>
    </Box >
  );
};

export default DrawerComponent;
