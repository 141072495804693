import React, { useState, useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Add custom modules for image resizing
import ImageResize from 'quill-image-resize-module-react';
import QuillTable from 'quill-table-ui';

// Register Quill modules
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/table', QuillTable);

const RichEditor = ({ value, onChange }: { value: string; onChange: (content: string) => void }) => {
    const quillModules = {
        toolbar: [
            // Formatting options
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ size: ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ color: [] }, { background: [] }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ align: [] }],
            ['blockquote', 'code-block'],

            // Direction
            [{ direction: 'rtl' }, { direction: 'ltr' }],

            // Links, Images, Videos, and Tables
            ['link', 'image', 'video'],
            [{ table: 'insert-table' }],

            // Clear formatting
            ['clean'],

            // Undo/Redo
            ['undo', 'redo'],
        ],
        imageResize: {
            modules: ['Resize', 'DisplaySize', 'Toolbar'],
        },
        table: true,
    };

    const quillFormats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'color',
        'background',
        'script',
        'list',
        'bullet',
        'indent',
        'align',
        'blockquote',
        'code-block',
        'direction',
        'link',
        'image',
        'video',
        'table',
    ];

    // Add extra lines at the beginning
    useEffect(() => {
        if (!value || value.trim() === '') {
            onChange('<p>Write your message here...<br></p><p><br></p><p><br></p><p><br></p><p><br></p><p><br></p>');
        }
    }, [value, onChange]);

    return (
        <ReactQuill
            theme="snow"
            value={value}
            onChange={onChange}
            modules={quillModules}
            formats={quillFormats}
            placeholder="Write your message here..."
        />
    );
};

export default RichEditor;
