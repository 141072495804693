import React, { useEffect, FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../store';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField, CheckboxWithLabel } from 'formik-material-ui';
import { Box, Button, CircularProgress, Container, Typography, Alert, Paper } from '@mui/material';
import * as yup from 'yup';
import { RootState } from '../store';
import { SignInUser } from '../features/userSlice';
import { useNavigate } from 'react-router-dom';
import { local } from 'd3';
import logo from '../images/logo1.png';


interface FormValues {
  email: string;
  password: string;
  userName: string;
  firstName: string;
  lastName: string;
  phone: string;
  isBusinessUser: boolean;
}

const initialValues: FormValues = {
  email: '',
  password: '',
  userName: '',
  firstName: '',
  lastName: '',
  phone: '',
  isBusinessUser: false,
};
interface signInResult {
  payload?: {
    token?: string;
    refreshToken?: string;
  }
}
const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
  password: yup.string().min(8, "Password should be minimum 8 char length").required('Password is required'),
  // userName: yup.string().required('Username is required'),
  // firstName: yup.string().min(2, 'First Name must be at least 2 characters').max(50, 'First Name must be at most 50 characters').required('First Name is required'),
  // lastName: yup.string().min(2, 'Last Name must be at least 2 characters').max(50, 'Last Name must be at most 50 characters').required('Last Name is required'),
  phone: yup.string().matches(/^[0-9]+$/, "Phone number must be numeric"),
  isBusinessUser: yup.boolean().required('User type is required'),
});

export const SignUp: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { status, error, isAuthenticated, data } = useSelector((state: RootState) => state.user);


  useEffect(() => {

    if (isAuthenticated && data) {
      localStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('refreshToken', data.refreshToken);
      localStorage.setItem('permissions', JSON.stringify(data.permissions));
      localStorage.setItem('menuPermissions', JSON.stringify(data.menuPermissions ? data.menuPermissions : {}));
      //localStorage.setItem('userId', data.data.userId);
      //window.location.href = '/home';
      navigate('/home', { state: { fromSignup: true } });
    }
  }, [isAuthenticated, data, navigate]);

  const onSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    try {
      await dispatch(SignInUser(values));
      setSubmitting(false);
    }
    catch (err) {
      console.log(err);
      setSubmitting(false);
    }
  };

  const renderForm = () => (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Field
            component={TextField}
            name="userName"
            type="text"
            label="Username"
            fullWidth
            margin="normal"
          />
          <Field
            component={TextField}
            name="email"
            type="email"
            label="Email"
            fullWidth
            margin="normal"
          />
          <Field
            component={TextField}
            type="password"
            label="Password"
            name="password"
            fullWidth
            margin="normal"
          />

          {/* <Field
            component={TextField}
            name="firstName"
            type="text"
            label="First Name"
            fullWidth
            margin="normal"
          />
          <Field
            component={TextField}
            name="lastName"
            type="text"
            label="Last Name"
            fullWidth
            margin="normal"
          /> */}
          {/* <Field
            component={TextField}
            name="phone"
            type="tel"
            label="Phone Number"
            fullWidth
            margin="normal"
          /> */}
          {/* <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="isBusinessUser"
            Label={{ label: 'Are you a Business User?' }}
          /> */}
          <Box display="flex" justifyContent="flex-end" mt={2}>
            {status === 'loading' ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Sign Up
              </Button>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );

  return (
    <Container maxWidth="xs">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Paper elevation={3} style={{ padding: '2em', width: '100%' }}>
          <img src={logo} alt="Logo" style={{ width: '120px', marginLeft: '120px' }} />
          {error && (
            <Alert severity="error">{error}</Alert>
          )}
          {renderForm()}
        </Paper>
      </Box>
    </Container>
  );
};

