

import axiosInstance from "../interceptor";
import { CHATGPT_API_URL, GATEWAY_API_URL } from "../api";


const api = '/api/monitoringService/api/v1/monitoring';
const monitoringPage = '/api/monitoringService/api/v1/monitoringPage';

export const reportOnQuestion = async (articleId: string, topicNumber: string, questionId: string, newVoteType: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/reportOnQuestion`, { articleId, topicNumber, questionId, newVoteType });
}
export const getAllQuizMonitoringDataAndQuizMonitoringUserData = async () => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}/getAllQuizMonitoringDataAndQuizMonitoringUserData`);
}

export const createMonitoringUsageOfMovie = async (movieId: string, courseId: string, subMovieId: string, usageType: string, startTime: string, endTime: string, subject: string, subSubject: string, length: string, startPage: string, endPage: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/createMonitoringUsageOfMovie`, { movieId, courseId, subMovieId, usageType, startTime, endTime, subject, subSubject, length, startPage, endPage });
}
export const createMonitoringUserUsageOpenSummary = async (movieId: string, subMovieId: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/createMonitoringUserUsageOpenSummary`, { movieId, subMovieId });
}
export const createMonitoringReferenceUsage = async (courseId: string, movieId: string, usageType: string, startTime: string, endTime: string, frameTime: string, pageNumber: string, score: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/createMonitoringReferenceUsage`, { courseId, movieId, usageType, startTime, endTime, frameTime, pageNumber, score });
}
export const createMonitoringOpenQuestion = async (courseId: string, searchId: string, questionId: string, question: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/createMonitoringOpenQuestion`, { courseId, searchId, questionId, question });
}
export const createMonitoringDeleteAllQuestion = async (courseId: string, searchId: string, answerDataId: string, question: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/createMonitoringDeleteAllQuestion`, { courseId, searchId, answerDataId, question });
}
export const createMonitoringDeleteMovieQuestion = async (courseId: string, searchId: string, searchIdMovie: string, movieId: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/createMonitoringDeleteMovieQuestion`, { courseId, searchId, searchIdMovie, movieId });
}
export const createMonitoringDeleteAnswerQuestion = async (courseId: string, searchId: string, questionId: string, question: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/createMonitoringDeleteAnswerQuestion`, { courseId, searchId, questionId, question });
}
export const createMonitoringAddToPlaylistUsage = async (courseId: string, videoId: string, audioFileName: string, startTime: string, endTime: string, pageNumber: string, type: string, title: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/createMonitoringAddToPlaylistUsage`, { courseId, videoId, audioFileName, startTime, endTime, pageNumber, type, title, });
}
export const getMonitoringMaxByCourseIdAndDomain = async (courseId: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/getMonitoringMaxByCourseIdAndDomain`, { courseId });
}
export const getMonitoringUserCourseDomainMaxQuestion = async (courseId: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/getMonitoringUserCourseDomainMaxQuestion`, { courseId });
}
export const resetTheNumberAtTheBeginningOfAMonthAccordingToTheFirstDay = async (courseId: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/resetTheNumberAtTheBeginningOfAMonthAccordingToTheFirstDay`, { courseId });
}
export const getAllUserUsageWithAudioFileName = async () => {
  return axiosInstance.get(`${GATEWAY_API_URL}${monitoringPage}/getAllUserUsageMovieAndSubMovies`);
}
export const getAllReferenceUsageMovieAndSubMovies = async () => {
  return axiosInstance.get(`${GATEWAY_API_URL}${monitoringPage}/getAllReferenceUsageMovieAndSubMovies`);
}
export const createMonitoringLikedDislikedAllQuestion = async (courseId: string, searchId: string, answerDataId: string, question: string, likeStatus: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/createMonitoringLikedDislikedAllQuestion`, { courseId, searchId, answerDataId, question, likeStatus });
}
export const createMonitoringLikedDislikedAnswerInQuestion = async (courseId: string, searchId: string, answerDataId: string, question: string, likeStatus: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/createMonitoringLikedDislikedAnswerInQuestion`, { courseId, searchId, answerDataId, question, likeStatus });
}
export const createMonitoringLikedDislikedMovieInQuestion = async (courseId: string, searchId: string, answerDataId: string, vectorId: string, title: string, likeStatus: string, usageType: string, startTime: string, endTime: string, frameTime: string, pageNumber: string, score: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/createMonitoringLikedDislikedMovieInQuestion`, { courseId, searchId, answerDataId, vectorId, title, likeStatus, usageType, startTime, endTime, frameTime, pageNumber, score });
}
export const createMonitoringUserAnswerQuiz = async (questionId: string, articleId: string, topicNumber: string, topic: string, userAnswer: string, correctAnswer: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/createMonitoringUserAnswerQuiz`, { questionId, articleId, topicNumber, topic, userAnswer, correctAnswer });
}
export const createMonitoringUserOpenAnswerQuiz = async (questionId: string, articleId: string, topicNumber: string, topic: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/createMonitoringUserOpenAnswerQuiz`, { questionId, articleId, topicNumber, topic });
}