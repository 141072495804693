import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Grid, IconButton } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import DeleteIcon from '@mui/icons-material/Delete';

const VideoLinkComponent: React.FC = () => {
    const [videoLinks, setVideoLinks] = useState<string[]>([]);
    const [newVideoLink, setNewVideoLink] = useState<string>('');

    const handleAddVideoLink = () => {
        if (newVideoLink.trim()) {
            setVideoLinks((prev) => [...prev, newVideoLink]);
            setNewVideoLink('');
        }
    };

    const handleDeleteVideoLink = (index: number) => {
        setVideoLinks((prev) => prev.filter((_, i) => i !== index));
    };

    return (
        <Box>
            <Typography variant="h6">Video Links</Typography>
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                <TextField
                    label="Add Video Link"
                    variant="outlined"
                    value={newVideoLink}
                    onChange={(e) => setNewVideoLink(e.target.value)}
                    fullWidth
                />
                <Button
                    variant="contained"
                    onClick={handleAddVideoLink}
                    startIcon={<LinkIcon />}
                >
                    Add Link
                </Button>
            </Box>
            <Grid container spacing={2}>
                {videoLinks.map((link, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4}>
                        <iframe
                            src={link}
                            title={`Video ${index + 1}`}
                            width="100%"
                            height="200"
                            allowFullScreen
                        />
                        <IconButton
                            color="error"
                            onClick={() => handleDeleteVideoLink(index)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default VideoLinkComponent;
