import React, { useState } from 'react';
import { Box, Typography, Button, Grid, IconButton, TextField } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactPlayer from 'react-player';
import { Document, Page, pdfjs } from 'react-pdf';
import { uploadFile } from '../../api/serverApis/filesApi';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface FileUploadProps {
    onDelete: (index: number) => void;
}

const FileUploadComponent: React.FC<FileUploadProps> = ({ onDelete }) => {
    const [uploadedFiles, setUploadedFiles] = useState<
        { url: string; file: File; name: string; type: string; content?: string }[]
    >([]);
    const [textFileContent, setTextFileContent] = useState<{ [key: number]: string }>({});
    const [pdfPageCount, setPdfPageCount] = useState<{ [key: number]: number }>({});

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const newFiles = await Promise.all(
                Array.from(files).map(async (file) => {
                    let content: string | undefined;
                    if (file.type === 'text/plain') {
                        content = await file.text();
                    }
                    return {
                        url: URL.createObjectURL(file),
                        file, // Store the original File object
                        name: file.name,
                        type: file.type.split('/')[0],
                        content,
                    };
                })
            );
            setUploadedFiles((prev) => [...prev, ...newFiles]);
        }
    };

    const handleTextChange = (index: number, value: string) => {
        setTextFileContent((prev) => ({ ...prev, [index]: value }));
        setUploadedFiles((prev) =>
            prev.map((file, i) => (i === index ? { ...file, content: value } : file))
        );
    };

    const onPdfLoadSuccess = (index: number, pdf: any) => {
        setPdfPageCount((prev) => ({ ...prev, [index]: pdf.numPages }));
    };

    const handleUpload = async (index: number) => {
        const fileData = uploadedFiles[index];
        try {
            const response = await uploadFile(fileData.file); // Pass the original File object
            console.log('Uploaded successfully:', response.data);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    return (
        <Box>
            <Typography variant="h6">File Uploads</Typography>
            <Button
                variant="contained"
                component="label"
                startIcon={<CloudUploadIcon />}
            >
                Upload Files
                <input type="file" hidden multiple onChange={handleFileUpload} />
            </Button>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                {uploadedFiles.map((file, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4}>
                        <Box sx={{ p: 2, border: '1px solid #ccc', borderRadius: '8px' }}>
                            {file.type === 'audio' && <audio controls src={file.url} />}
                            {file.type === 'video' && (
                                <ReactPlayer url={file.url} controls width="100%" />
                            )}
                            {file.type === 'application' &&
                                file.name.endsWith('.pdf') && (
                                    <Box>
                                        <Document
                                            file={file.url}
                                            onLoadSuccess={(pdf) => onPdfLoadSuccess(index, pdf)}
                                        >
                                            {Array.from(
                                                new Array(pdfPageCount[index]),
                                                (_, pageIndex) => (
                                                    <Page
                                                        key={pageIndex}
                                                        pageNumber={pageIndex + 1}
                                                        width={300}
                                                    />
                                                )
                                            )}
                                        </Document>
                                    </Box>
                                )}
                            {file.type === 'text' && (
                                <TextField
                                    label="Edit Text"
                                    multiline
                                    fullWidth
                                    rows={6}
                                    value={textFileContent[index] || file.content || ''}
                                    onChange={(e) => handleTextChange(index, e.target.value)}
                                />
                            )}
                            <Typography>{file.name}</Typography>
                            <TextField
                                label="Rename File"
                                variant="outlined"
                                fullWidth
                                size="small"
                                value={file.name}
                                onChange={(e) =>
                                    setUploadedFiles((prev) =>
                                        prev.map((f, i) =>
                                            i === index ? { ...f, name: e.target.value } : f
                                        )
                                    )
                                }
                            />
                            <IconButton
                                color="primary"
                                onClick={() => handleUpload(index)}
                            >
                                <CloudUploadIcon />
                            </IconButton>
                            <IconButton
                                color="error"
                                onClick={() => {
                                    setUploadedFiles((prev) =>
                                        prev.filter((_, i) => i !== index)
                                    );
                                    onDelete(index);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default FileUploadComponent;
