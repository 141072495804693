

import { makeStyles } from '@mui/styles';
import { Paper } from '@mui/material';


const useStyles = makeStyles({
  fixedBox: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1200,

  },
  rightFixedBox: (theme: any) => ({
    position: 'fixed',
    paddingTop: '1rem',
    top: 40,
    right: 20,
    width: '38%',
    height: '4rem',
    background: theme.palette.background.Paper,
    verticalAlign: 'center',
    zIndex: 10,
    [theme.breakpoints.down('sm')]: {
      width: '100%', // Full width on small screens
      right: 0,
    },
  }),
  mainContentBox: (theme: any) => ({
    flexGrow: 1,
    marginTop: '2rem',
    background: theme.palette.background.Paper,
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem', // Less margin on small screens
    },
  }),
  videoLayoutBox: {
    display: 'block',
  },
  layoutsBox: (theme: any) => ({
    marginTop: '2rem',
    overflowY: 'hidden',
    background: theme.palette.background.Paper,
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem', // Adjust layout margins for small screens
    },
  })
});

export default useStyles;