import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getVideoFiles, getPdfFiles, getFileMp4MetaData, getFileMp4FileSize, getVideoFilesWithoutRange, getMp3FileSize } from '../api/serverApis/filesApi';
import { getFileFromCache, saveFileToCache } from '../utils/indexedDb'

interface BlobState {
  savedUrls: { [key: string]: string };
  loading: boolean;
  error: string | null;
}

const initialState: BlobState = {
  savedUrls: {},
  loading: false,
  error: null,
};


export const fetchBlob = createAsyncThunk('blobs/fetchBlob', async (url: string, { getState, dispatch }) => {
  const state = getState() as any; // Adjust this type to match your RootState
  const existingUrl = state.blobs.savedUrls[url];

  if (existingUrl) {
    return existingUrl;
  }
  // Check if the file is already in IndexedDB
  let cachedBlob = await getFileFromCache(url);
  if (cachedBlob) {
    const cachedUrl = URL.createObjectURL(cachedBlob);
    dispatch(saveUrl({ url, localUrl: cachedUrl }));
    return cachedUrl;
  }
  let response;
  // if (url.includes('.mp4')) {
  //   response = await getVideoFiles(url);
  // }
  //else
  if (url.includes('.mp3') || url.includes('.opus')) {
    response = await getMp3FileSize(url);
  }
  if (url.includes('.pdf')) {
    response = await getPdfFiles(url);
  }
  if (!response) {
    throw new Error('Unsupported file type');
  }

  const blob = new Blob([response.data], {
    type: url.includes('.mp3') ? 'audio/mpeg'
      : url.includes('.opus') ? 'audio/ogg; codecs=opus'
        : url.includes('.mp4') ? 'video/mp4'
          : 'application/pdf',
  });
  // Save the blob to IndexedDB for future use
  await saveFileToCache(url, blob);

  const localUrl = URL.createObjectURL(blob);

  dispatch(saveUrl({ url, localUrl }));
  return localUrl;
}
);


export const fetchBlobToMp4 = createAsyncThunk(
  'blobs/fetchBlobToMp4',
  async (url: string, { getState, dispatch }) => {
    const state = getState() as any;
    const cachedBlob = await getFileFromCache(url); // Check if the video is cached

    if (cachedBlob) {
      const cachedUrl = URL.createObjectURL(cachedBlob);
      dispatch(saveUrl({ url, localUrl: cachedUrl })); // Use cached URL if available
      return cachedUrl;
    }

    const CHUNK_SIZE = 4 * 1024 * 1024; // 4MB chunk size for progressive streaming
    let start = 0;
    let end = CHUNK_SIZE - 1;
    const blobParts: BlobPart[] = [];
    let totalBlobSize = 0;

    // Fetch chunks in a loop
    let response = await getVideoFiles(url, start, end);
    while (response.status === 206) {
      blobParts.push(response.data);
      totalBlobSize += response.data.size;

      // Calculate the next chunk range
      start = end + 1;
      end = start + CHUNK_SIZE - 1;
      response = await getVideoFiles(url, start, end);
    }

    const videoBlob = new Blob(blobParts, { type: 'video/mp4' });
    await saveFileToCache(url, videoBlob); // Cache video in IndexedDB

    const localUrl = URL.createObjectURL(videoBlob);
    dispatch(saveUrl({ url, localUrl }));
    return localUrl;
  }
);

const blobSlice = createSlice({
  name: 'blobs',
  initialState,
  reducers: {
    saveUrl: (state, action: PayloadAction<{ url: string; localUrl: string }>) => {
      state.savedUrls[action.payload.url] = action.payload.localUrl;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlob.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBlob.fulfilled, (state, action) => {
        state.loading = false;
        state.savedUrls[action.payload.url] = action.payload as string;
      })
      .addCase(fetchBlob.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch blob';
      })
      .addCase(fetchBlobToMp4.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBlobToMp4.fulfilled, (state, action) => {
        state.loading = false;
        //state.savedUrls[action.payload.url] = action.payload as string; // Use the arg (url) as the key
      })
      .addCase(fetchBlobToMp4.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch MP4';
      });
  },
});

export const { saveUrl } = blobSlice.actions;

export const selectUrlByBlob = (state: any, url: string) => state.blobs.savedUrls[url];

export default blobSlice.reducer;
