import React, { useState, useRef } from 'react';
import { Box, Typography, Grid, IconButton, Tooltip, TextField, Checkbox, FormControlLabel, RadioGroup, FormControl, FormLabel, Radio } from '@mui/material';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import StopIcon from '@mui/icons-material/Stop';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { uploadFile } from '../../api/serverApis/filesApi';

interface ScreenRecordingProps {
    onDelete: (index: number) => void;
}

const ScreenRecordingComponent: React.FC<ScreenRecordingProps> = ({ onDelete }) => {
    const [recordingScreen, setRecordingScreen] = useState(false);
    const [recordMicrophone, setRecordMicrophone] = useState(true); // Checkbox state for microphone
    const [recordVideo, setRecordVideo] = useState(false); // Radio button for video recording
    const [screenRecordings, setScreenRecordings] = useState<{ url: string; blob: Blob | null; name: string }[]>([]);
    const [previewStream, setPreviewStream] = useState<MediaStream | null>(null);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const chunksRef = useRef<Blob[]>([]);

    const handleScreenRecord = async () => {
        if (!recordingScreen) {
            try {
                // Capture screen
                const screenStream = await navigator.mediaDevices.getDisplayMedia({ video: true });

                let combinedStream;
                if (recordMicrophone) {
                    // Capture microphone audio if checkbox is checked
                    const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
                    combinedStream = new MediaStream([
                        ...screenStream.getTracks(),
                        ...audioStream.getTracks(),
                    ]);
                } else {
                    combinedStream = screenStream;
                }

                if (recordVideo) {
                    // Capture camera video if the user enables the option
                    const cameraStream = await navigator.mediaDevices.getUserMedia({ video: true });
                    combinedStream.addTrack(cameraStream.getVideoTracks()[0]);
                    setPreviewStream(cameraStream); // Set camera stream for live preview
                }

                mediaRecorderRef.current = new MediaRecorder(combinedStream);
                chunksRef.current = [];

                mediaRecorderRef.current.ondataavailable = (e) => {
                    chunksRef.current.push(e.data);
                };

                mediaRecorderRef.current.onstop = () => {
                    const blob = new Blob(chunksRef.current, { type: 'video/mp4' });
                    const url = URL.createObjectURL(blob);
                    setScreenRecordings((prev) => [...prev, { url, blob, name: '' }]); // Include blob and name
                    setPreviewStream(null); // Stop preview once recording ends
                };

                mediaRecorderRef.current.start();
                setRecordingScreen(true);
            } catch (error) {
                console.error('Error starting screen recording:', error);
            }
        } else {
            mediaRecorderRef.current?.stop();
            previewStream?.getTracks().forEach((track) => track.stop());
            setRecordingScreen(false);
        }
    };

    const handleFileNameChange = (index: number, name: string) => {
        setScreenRecordings((prev) =>
            prev.map((recording, i) => (i === index ? { ...recording, name } : recording))
        );
    };

    const handleUpload = async (index: number) => {
        const recording = screenRecordings[index];
        if (recording.blob) {
            const file = new File([recording.blob], recording.name || `ScreenRecording_${index + 1}.mp4`, {
                type: 'video/mp4',
            });

            try {
                const response = await uploadFile(file);
                console.log('Uploaded successfully:', response.data);
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };

    const handleDelete = (index: number) => {
        setScreenRecordings((prev) => prev.filter((_, i) => i !== index));
        onDelete(index); // Call the parent onDelete function if needed
    };

    return (
        <Box>
            <Typography variant="h6">Screen Recordings</Typography>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    mb: 2,
                }}
            >
                <Tooltip title="Record Screen with Microphone">
                    <IconButton
                        color={recordingScreen ? 'secondary' : 'primary'}
                        onClick={handleScreenRecord}
                    >
                        {recordingScreen ? <StopIcon /> : <ScreenShareIcon />}
                    </IconButton>
                </Tooltip>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={recordMicrophone}
                            onChange={(e) => setRecordMicrophone(e.target.checked)}
                            color="primary"
                        />
                    }
                    label="Record Microphone"
                />
                <FormControl component="fieldset">
                    <FormLabel component="legend">Options</FormLabel>
                    <RadioGroup
                        row
                        value={recordVideo}
                        onChange={(e) => setRecordVideo(e.target.value === 'true')}
                    >
                        <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="Record Camera Video"
                        />
                        <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="Screen Only"
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
            {previewStream && (
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 20,
                        right: 20,
                        width: '200px',
                        height: '150px',
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        overflow: 'hidden',
                        background: '#000',
                    }}
                >
                    <video
                        autoPlay
                        muted
                        playsInline
                        style={{ width: '100%', height: '100%' }}
                        ref={(video) => {
                            if (video) video.srcObject = previewStream;
                        }}
                    />
                </Box>
            )}
            <Grid container spacing={2}>
                {screenRecordings.map((recording, index) => (
                    <Grid item key={index} xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 2,
                                p: 2,
                                border: '1px solid #ccc',
                                borderRadius: '8px',
                            }}
                        >
                            <video controls src={recording.url} style={{ flex: 2 }} width="300" />
                            <TextField
                                label="File Name"
                                variant="outlined"
                                size="small"
                                value={recording.name || ''}
                                onChange={(e) => handleFileNameChange(index, e.target.value)}
                                sx={{ flex: 1 }}
                            />
                            <Tooltip title="Upload">
                                <IconButton color="primary" onClick={() => handleUpload(index)}>
                                    <CloudUploadIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton
                                    color="error"
                                    onClick={() => handleDelete(index)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default ScreenRecordingComponent;
