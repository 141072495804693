import axiosInstance from "../interceptor";
import { GATEWAY_API_URL } from "../api";


const api = '/api/analyzeContentService/api/v1/analyzeContent';




// Function to retrieve a single message with its attachments
export const getAllTable = async () => {
    return axiosInstance.get(`${GATEWAY_API_URL}${api}/getAllTable`);
};

