
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getQuizByArticleId, getQuizByArticleIdAndTopicNumber, getTopicsNumberByArticleId } from '../api/serverApis/quizApi';

export interface quizState {
  quizData: any;
  loading: boolean;
  error: string | null | undefined;
  topicsNumber: any;
  currentVideo: { movieId: string; subVideoId: string | null } | null;
}

const initialState: quizState = {
  quizData: null,
  loading: false,
  error: null,
  topicsNumber: null,
  currentVideo: null,
};

export const getQuizByArticleIdSlice = createAsyncThunk('quiz/getQuizByArticleIdSlice', async ({ articleId }: { articleId: string }) => {
  const response = await getQuizByArticleId(articleId);
  return response.data;
});

export const getQuizArticleIdAndTopicNumberSlice = createAsyncThunk('quiz/getQuizArticleIdAndTopicNumberSlice', async ({ articleId, topicNumber }: { articleId: string, topicNumber: string }) => {
  const response = await getQuizByArticleIdAndTopicNumber(articleId, topicNumber);
  return response.data;
});
export const getTopicsNumberByArticleIdSlice = createAsyncThunk('quiz/getTopicsNumberByArticleIdSlice', async ({ articleId }: { articleId: string }) => {
  const response = await getTopicsNumberByArticleId(articleId);
  return response.data;
});

const handleState = (action: any) => {
  return (builder: any) => {
    builder
      .addCase(action.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(action.fulfilled, (state: any, action: PayloadAction<any>) => {
        state.loading = false;
        if (action.type === 'quiz/getQuizByArticleIdSlice/fulfilled') {
          state.quizData = action.payload;
        }
        else if (action.type === 'quiz/getQuizArticleIdAndTopicNumberSlice/fulfilled') {
          state.quizData = action.payload;
        }
        else if (action.type === 'quiz/getTopicsNumberByArticleIdSlice/fulfilled') {
          state.topicsNumber = action.payload;
        }
      })
      .addCase(action.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      });
  };
}

const quizSlice = createSlice({
  name: 'quiz',
  initialState: initialState,
  reducers: {
    setCurrentVideo(state, action: PayloadAction<{ movieId: string; subVideoId: string }>) {
      state.currentVideo = action.payload;
    },
  },
  extraReducers: (builder) => {
    handleState(getQuizByArticleIdSlice)(builder);
    handleState(getQuizArticleIdAndTopicNumberSlice)(builder);
    handleState(getTopicsNumberByArticleIdSlice)(builder);
  },
});
export const { setCurrentVideo } = quizSlice.actions;
export default quizSlice.reducer;

