// Disclaimer.tsx
import React, { FC, useState, useEffect } from 'react';
import { Box, Button, Divider, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Typography, CircularProgress, Alert, Paper } from '@mui/material';
import logo from '../../images/logo1.png';
import { getDisclaimer, createDisclaimerAcceptedApi } from '../../api/serverApis/userApi';
import { useNavigate, Link } from 'react-router-dom';
interface DisclaimerProps {
  open: boolean;
  onClose?: () => void;
  onCloseBoolean?: (accepted: boolean) => void;
}

export const Disclaimer: FC<DisclaimerProps> = ({ open, onClose, onCloseBoolean }) => {
  const [disclaimerText, setDisclaimerText] = useState('');
  const [disclaimerText2, setDisclaimerText2] = useState('');
  const [disclaimerText3, setDisclaimerText3] = useState('');
  const [disclaimerPageId, setDisclaimerPageId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isAccepted, setIsAccepted] = useState(false);
  const isHebrew = (text: string) => /[\u0590-\u05FF\uFB1D-\uFB4F\uFB50-\uFDFF]/.test(text);
  const isArabic = (text: string) => /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF]/.test(text);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchDisclaimer = async () => {
      try {
        setIsLoading(true);
        const response = await getDisclaimer();// Adjust endpoint as needed
        if (response.data === "no disclaimer page") {
          if (onCloseBoolean) {
            onCloseBoolean(true);
          }
          if (onClose) {
            onClose();
          }
        }
        setDisclaimerPageId(response.data.disclaimerPageId);
        setDisclaimerText(response.data.pageContent);
        setDisclaimerText2(response.data.pageContent2);
        setDisclaimerText3(response.data.pageContent3);
        setError(null);
      } catch (err) {
        setError('Failed to load disclaimer');
      } finally {
        setIsLoading(false);
      }
    };
    if (open) fetchDisclaimer();
  }, [open]);

  const handleAcceptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAccepted(event.target.checked);
  };

  const handleSend = async () => {
    if (isAccepted) {
      try {
        const result = await createDisclaimerAcceptedApi(disclaimerPageId);

        // Assuming result has status or success indicators
        if (result.status === 200 || result.success) {
          if (onCloseBoolean) {
            onCloseBoolean(true);
          }
          if (onClose) {
            onClose();
          }
        } else {
          // Redirect to login if there's an error (like 400, 404, 500)
          navigate('/login');
        }
      } catch (error) {
        // In case of an error, log it and redirect to login
        console.error('Error accepting disclaimer:', error);
        navigate('/login');
      }
    }
  };
  const handleCloseDialog = () => {
    if (onCloseBoolean) {
      onCloseBoolean(false);
    }
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog} sx={{ zIndex: 99999 }}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
          <img src={logo} alt="Logo" style={{ width: '80px' }} />
        </Box>
        Disclaimer
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <CircularProgress />
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <Paper elevation={0} style={{ padding: '1em' }}>
            <Typography sx={{ direction: isHebrew(disclaimerText) ? 'rtl' : 'ltr', mb: 1 }}>
              {disclaimerText}
            </Typography>
            <Divider sx={{ my: 3 }} /> {/* Divider between texts */}
            <Typography sx={{ direction: isHebrew(disclaimerText2) ? 'rtl' : 'ltr', mb: 1 }}>
              {disclaimerText2}
            </Typography>
            {disclaimerText2 && <Divider sx={{ my: 3 }} />} {/* Divider between texts */}
            <Typography sx={{ direction: isArabic(disclaimerText3) ? 'rtl' : 'ltr', mb: 1 }}>
              {disclaimerText3}
            </Typography>
            <Box mt={2}>
              <Checkbox
                checked={isAccepted}
                onChange={handleAcceptChange}
                color="primary"
              />
              <Typography variant="body2" display="inline">
                I accept the terms and conditions.
              </Typography>
            </Box>
          </Paper>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSend}
          disabled={!isAccepted || isLoading}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};
