import React, { useEffect, useState } from 'react';
import { useTheme, Badge, AppBar, Toolbar, Typography, Box, Switch, FormControlLabel, Menu, MenuItem, Tooltip, IconButton } from '@mui/material';
import logo from '../../images/logo1.png'; // Adjust the path as necessary
import { getUserName } from '../../api/serverApis/userApi';
import { logout, getUserNameSlice } from '../../features/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import LogoutIcon from '@mui/icons-material/Logout';
import AvatarMenuComponent from '../avatarMenuComponents/avatarMenuComponent';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { fetchUnreadCount } from '../../features/messagingSlice';
import { useNavigate } from 'react-router-dom';
interface HeaderProps {
  themeMode: 'light' | 'dark';
  toggleTheme: () => void; // Function to toggle the theme
  header?: string,
  title?: string
}

const Header: React.FC<HeaderProps> = ({ themeMode, toggleTheme, header, title }) => {
  const handleLogoClick = () => {
    window.location.href = '/home'; // Redirect to the home page when the logo is clicked
  };
  //const [userName, setUserName] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const userName = useSelector((state: RootState) => state.user.userName);
  //const [unreadCount, setUnreadCount] = useState<number>(0);
  const theme = useTheme();
  const navigate = useNavigate();
  const messaging = useSelector((state: RootState) => state.messaging);

  useEffect(() => {
    dispatch(getUserNameSlice());
  }, []);


  useEffect(() => {
    const fetchUnreadMessages = async () => {
      try {
        await dispatch(fetchUnreadCount());
      } catch (error) {
        console.error("Error fetching unread messages:", error);
      }
    };

    fetchUnreadMessages();

    // Poll for new messages every 60 seconds
    const interval = setInterval(fetchUnreadMessages, 600000);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);


  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleHome = () => {
    handleMenuClose();
    window.location.href = '/home'; // Redirect to home
  };
  // Handle menu close
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle logout
  const handleLogout = () => {
    dispatch(logout());
    handleMenuClose();
    window.location.href = '/login'; // Redirect to login after logout
  };
  const handleBadgeClick = () => {
    navigate('/messaging'); // Redirect to the messaging page
  };

  return (
    <Box
      position="static"
      sx={{
        height: '3.5rem',
        justifyContent: 'center', // Centers content vertically
        alignItems: 'center',
        overflow: 'hidden',
        // Different background for light and dark mode
        //backgroundColor: themeMode === 'dark' ? '#424242' : '#F0F8FF',
        backgroundColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Toolbar sx={{
        minHeight: '3rem !important', // Ensures the Toolbar respects the AppBar height, overriding default styles
        alignItems: 'center', // Ensure the content inside the Toolbar is centered vertically
        justifyContent: 'space-between', // Spaces out logo and switch
        width: '100%', // Ensures the Toolbar spans the entire width of the AppBar
      }}>
        <Box display="flex" alignItems="center" height="100%"  >
          <Box onClick={handleHome} sx={{ cursor: 'pointer' }}>
            <img src={logo} alt="Logo" style={{ marginTop: '0rem', marginRight: 10, width: "100px", height: 'auto' }} />
            {/* <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              sx={{ mt: '35px' }}
            >
              <MenuItem onClick={handleHome}>Home</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu> */}
          </Box>
          {userName && (
            <Typography
              fontSize="1rem"
              noWrap
              sx={{
                marginLeft: 2, // Add some spacing between the title and user name
                color: themeMode === 'dark' ? '#ffffff' : '#000000', // Adjust the color based on theme
              }}
            >
              {`Hello, ${userName}`}
            </Typography>
          )}
          <Typography
            fontSize="1rem"
            noWrap
            sx={{
              padding: '10px', color: themeMode === 'dark' ? '#ffffff' : '#000000', // Lighter in dark mode, darker in light mode
            }}
          > |
          </Typography>
          <Typography
            fontSize="1rem"
            noWrap
            sx={{
              color: themeMode === 'dark' ? '#ffffff' : '#000000', // Lighter in dark mode, darker in light mode
            }}
          >
            {header}
          </Typography>

          {title && <Typography
            fontSize="1rem"
            noWrap
            sx={{
              color: themeMode === 'dark' ? '#ffffff' : '#000000', // Lighter in dark mode, darker in light mode
            }}
          >{title}</Typography>}

        </Box>

        <Box display="flex" alignItems="center">
          <FormControlLabel
            sx={{
              color: themeMode === 'dark' ? '#ffffff' : '#000000', // Lighter in dark mode, darker in light mode
            }}
            control={<Switch checked={themeMode === 'dark'} onChange={toggleTheme} />}
            label={themeMode === 'dark' ? 'Dark Mode' : 'Light Mode'}
          />
          <Box sx={{ marginRight: "50px" }} >

            <Badge
              badgeContent={messaging.unreadCount}
              color="error"
              sx={{
                '& .MuiBadge-badge': {
                  top: 15, // Adjust the vertical position
                  right: 15, // Adjust the horizontal position
                  backgroundColor: 'red', // Set the badge background to red
                  color: 'white', // Set the badge text color to white
                  fontSize: '0.75rem', // Adjust font size
                },
              }}
            >
              <AvatarMenuComponent userName={userName} />
            </Badge>

          </Box>
        </Box>

      </Toolbar>
    </Box >
  );
};

export default Header;
