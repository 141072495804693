
import axiosInstance from "../interceptor";
import { CHATGPT_API_URL, GATEWAY_API_URL } from "../api";

const api = '/api/imageService/';
const apiFiles = '/api/imageService/api/v1/files'

export const getImagesFiles = async (directoryAndFile: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}images/${directoryAndFile}`, { responseType: 'blob' });
}
// export const getVideoFiles = async (directoryAndFile: string) => {
//   return axiosInstance.get(`${GATEWAY_API_URL}${api}videos/${directoryAndFile}`, { responseType: 'blob' });
// }
export const getVideoFiles = async (directoryAndFile: string, start: number, end: number) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}videos/${directoryAndFile}`, {
    headers: {
      Range: `bytes=${start}-${end}`
    },
    responseType: 'blob'
  });
};
export const getVideoFilesWithoutRange = async (directoryAndFile: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}videos/${directoryAndFile}`);
};
export const getFileMp4FileSize = async (directoryAndFile: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}videos/${directoryAndFile}`, {
    headers: {
      Range: 'metadataFileSize',
      'Content-Type': 'video/mp4'
    }
  });
}
export const getFileMp4MetaData = async (directoryAndFile: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}videos/${directoryAndFile}`, {
    headers: {
      Range: 'metadata',
      'Content-Type': 'video/mp4'
    }
  });
}
export const getPdfFiles = async (directoryAndFile: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}pdf/${directoryAndFile}`, { responseType: 'blob' });
}
export const getMp3FileSize = async (directoryAndFile: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}videos/${directoryAndFile}`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'audio/mpeg'
    }
  });
}
export const uploadFile = async (file: File) => {
  const formData = new FormData(); // Create a FormData instance
  formData.append('file', file); // Append the file
  for (let [key, value] of formData.entries()) {
    console.log(`${key}:`, value); // Logs each key-value pair
  }
  return axiosInstance.post(`${GATEWAY_API_URL}${apiFiles}/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Set the appropriate header
    },
  });
};