import axiosInstance from "../interceptor";
import { AUTH_API_URL, GATEWAY_API_URL } from "../api";
import { createDisclaimerAccepted } from '../../../../authenticationService/src/controllers/DisclaimerController';

//const API = "/api/v1/auth"; 
const API = '/api/authenticationService/api/v1/auth';
const disclaimerAPI = '/api/authenticationService/api/v1/disclaimer';
const userAPI = '/api/authenticationService/api/v1/users';
// export const checkAuthenticated = async () => {
//   return axiosInstance.get(`${AUTH_API_URL}${API}/isAuthenticated`);
// };

// export const signIn = async (user: { email: string, password: string, userName: string, firstName: string, lastName: string, phone: string, isBusinessUser: boolean }) => {
//   return axiosInstance.post(`${AUTH_API_URL}${API}/register`, {
//     user
//   });
// };
// export const login = async (email: string, password: string) => {
//   try {
//     return axiosInstance.post(`${AUTH_API_URL}${API}/login`, {
//       email,
//       password,
//     });
//   }
//   catch (error) {
//     console.error(error);
//     throw new Error('ServerSearchMediaService: [login]: ' + error);
//   }
// };
// export const permissions = async () => {
//   try {
//     return axiosInstance.get(`${AUTH_API_URL}${API}/permissions`);
//   }
//   catch (error) {
//     console.error(error);
//     throw new Error('ServerSearchMediaService: [login]: ' + error);
//   }
// }
// export const auth = async (token: string) => {
//   return axiosInstance.get(`${AUTH_API_URL}${API}/auth`);
// }

export const checkAuthenticated = async () => {
  return axiosInstance.get(`${GATEWAY_API_URL}${API}/isAuthenticated`);
};

export const signIn = async (user: { email: string, password: string, userName: string, firstName: string, lastName: string, phone: string, isBusinessUser: boolean }) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${API}/register`, {
    user
  });
};
export const login = async (email: string, password: string) => {
  try {
    return axiosInstance.post(`${GATEWAY_API_URL}${API}/login`, {
      email,
      password,
    });
  }
  catch (error) {
    console.error(error);
    throw new Error('userApi: [login]: ' + error);
  }
};
export const permissions = async () => {
  try {
    return axiosInstance.get(`${GATEWAY_API_URL}${API}/permissions`);
  }
  catch (error) {
    console.error(error);
    throw new Error('userApi: [login]: ' + error);
  }
}
export const auth = async (token: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${API}/auth`);
}

export const forgotPassword = async (email: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${API}/forgotPassword`, {
    email
  });
}
export const resetPasswordApi = async (token: string, newPassword: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${API}/resetPassword`, {
    token,
    newPassword
  });
}

export const getUserName = async () => {
  try {
    return axiosInstance.post(`${GATEWAY_API_URL}${API}/getUserName`, {});
  }
  catch (error) {
    console.error(error);
    throw new Error('userApi: [login]: ' + error);
  }
}

export const getDisclaimer = async () => {
  try {
    return axiosInstance.get(`${GATEWAY_API_URL}${disclaimerAPI}/getDisclaimer`);
  }
  catch (error) {
    console.error(error);
    throw new Error('userApi: [login]: ' + error);
  }
}

export const createDisclaimerAcceptedApi = async (disclaimerPageId: string) => {
  try {
    const response = await axiosInstance.post(`${GATEWAY_API_URL}${disclaimerAPI}/createDisclaimerAccepted`, { disclaimerPageId });

    // Return a success status if the response is 200 OK
    return {
      status: response.status,
      success: response.status === 200,
      data: response.data,
    };
  } catch (error: any) {
    // Handle and return error status, ensure response exists
    const status = error.response?.status || 500;

    return {
      status,
      success: false,
      error: error.message || 'Unknown error',
    };
  }
};

export const checkDomainForDisclaimerAndCheckDateOfUser = async () => {
  try {
    return axiosInstance.get(`${GATEWAY_API_URL}${disclaimerAPI}/checkDomainForDisclaimerAndCheckDateOfUser`);
  }
  catch (error) {
    console.error(error);
    throw new Error('userApi: [login]: ' + error);
  }
}

export const getUsers = async () => {
  try {
    return axiosInstance.get(`${GATEWAY_API_URL}${userAPI}/getAllUsers`);
  }
  catch (error) {
    console.error(error);
    throw new Error('userApi: [login]: ' + error);
  }
}