import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Card, CardContent, Divider, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { getAllUserUsageWithAudioFileName } from '../../api/serverApis/monitoringApi';
interface WatchData {
    count: number;
    users: Set<string>;
}
const MonitoringDashboard: React.FC = () => {
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);

    const [selectedUser, setSelectedUser] = useState<string>('');
    const [selectedMovie, setSelectedMovie] = useState<string>('');
    const [selectedCourse, setSelectedCourse] = useState<string>('');
    const [selectedDate, setSelectedDate] = useState<string>('');

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await getAllUserUsageWithAudioFileName();
                if (response.data) {
                    setData(response.data.response);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setData([]);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    if (loading) {
        return <Box textAlign="center" my={5}><CircularProgress /></Box>;
    }

    if (!data || data.length === 0) {
        return <Box textAlign="center" my={5}><Typography>No data available.</Typography></Box>;
    }

    const uniqueUsers = [...new Set(data.map(d => d.userId))];
    const uniqueMovies = [...new Set(data.map(d => d.audioFileName))];
    const uniqueCourses = [...new Set(data.map(d => d.courseId))];
    const uniqueDates = [...new Set(data.map(d => new Date(d.dateTime).toLocaleDateString()))];

    const userMovies = data.filter(d => d.userId === selectedUser);
    const movieUsers = data.filter(d => d.audioFileName === selectedMovie);
    const courseViews = data.filter(d => d.courseId === selectedCourse);
    const dateFilteredData = selectedDate ? data.filter(d => new Date(d.dateTime).toLocaleDateString() === selectedDate) : data;

    const totalUsers = uniqueUsers.length;
    const totalCourses = uniqueCourses.length;
    const totalVideos = uniqueMovies.length;

    const userWatchData = userMovies.reduce((acc: any, item: any) => {
        const { audioFileName, subMovieId, dateTime } = item;
        const key = `${audioFileName} - ${subMovieId}`;
        acc[key] = acc[key] || { name: audioFileName, subMovieId, views: 0, dates: {} };
        acc[key].views += 1;
        const date = new Date(dateTime).toLocaleDateString();
        acc[key].dates[date] = (acc[key].dates[date] || 0) + 1;
        return acc;
    }, {});

    const chartData = Object.values(userWatchData);

    const movieViewsData = movieUsers.reduce((acc: any, item: any) => {
        const { userId, subMovieId, dateTime } = item;
        const key = `${userId} - ${subMovieId}`;
        acc[key] = acc[key] || { userId, subMovieId, views: 0, dates: {} };
        acc[key].views += 1;
        const date = new Date(dateTime).toLocaleDateString();
        acc[key].dates[date] = (acc[key].dates[date] || 0) + 1;
        return acc;
    }, {});

    const movieViewsChartData = Object.values(movieViewsData);

    const courseData = Object.entries(
        data.reduce((acc, item) => {
            acc[item.courseId] = (acc[item.courseId] || 0) + 1;
            return acc;
        }, {} as Record<string, number>)
    ).map(([name, value]) => ({ name, value }));

    const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7f50'];

    const movieIdWatchData = data.reduce((acc: Record<string, WatchData>, item: any) => {
        if (!acc[item.audioFileName]) {
            acc[item.audioFileName] = { count: 0, users: new Set() };
        }
        acc[item.audioFileName].count += 1;
        acc[item.audioFileName].users.add(item.userId);
        return acc;
    }, {});


    const subMovieIdWatchData = data.reduce((acc: Record<string, WatchData>, item: any) => {
        const key = `${item.audioFileName} - ${item.subMovieId}`;
        if (!acc[key]) {
            acc[key] = { count: 0, users: new Set() };
        }
        acc[key].count += 1;
        acc[key].users.add(item.userId);
        return acc;
    }, {});

    const mostWatchedMovie = Object.entries(movieIdWatchData).sort((a: any, b: any) => b[1].count - a[1].count)[0];
    const mostWatchedSubMovie = Object.entries(subMovieIdWatchData).sort((a: any, b: any) => b[1].count - a[1].count)[0];

    // 3: Rank movies with subMovieId from most to least watched
    const rankedMovies = Object.entries(subMovieIdWatchData)
        .sort((a: any, b: any) => b[1].count - a[1].count)
        .map(([name, data]: [string, any]) => ({
            name,
            views: data.count,
            uniqueUsers: data.users.size
        }));


    return (
        <Box p={3}>
            <Typography variant="h4" gutterBottom>Movie Usages By User (watch from TOC)</Typography>
            <Divider sx={{ mb: 4 }} />

            {/* Quick Stats */}
            <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5">Total Users</Typography>
                            <Typography variant="h2">{totalUsers}</Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5">Total Courses</Typography>
                            <Typography variant="h2">{totalCourses}</Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5">Total Videos</Typography>
                            <Typography variant="h2">{totalVideos}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                {/* Most Watched Movie ID and Movie ID + SubMovie ID */}
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5">Most Watched Movie ID</Typography>
                            <Typography variant="body1">Movie ID: {mostWatchedMovie[0]}</Typography>
                            <Typography variant="body2">Views: {mostWatchedMovie[1].count}</Typography>
                            <Typography variant="body2">Unique Users: {mostWatchedMovie[1].users.size}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5">Most Watched Movie ID + SubMovie ID</Typography>
                            <Typography variant="body1">ID: {mostWatchedSubMovie[0]}</Typography>
                            <Typography variant="body2">Views: {mostWatchedSubMovie[1].count}</Typography>
                            <Typography variant="body2">Unique Users: {mostWatchedSubMovie[1].users.size}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Divider sx={{ my: 4 }} />

            {/* User Monitoring */}
            <Box my={4}>
                <Typography variant="h5" gutterBottom>User Monitoring</Typography>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Select User</InputLabel>
                    <Select
                        value={selectedUser}
                        onChange={(e) => setSelectedUser(e.target.value)}
                    >
                        {uniqueUsers.map(user => (
                            <MenuItem key={user} value={user}>{user}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {selectedUser && (
                    <Box my={4}>
                        <Typography variant="h6" gutterBottom>Movies Watched by Selected User</Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={chartData as any[]}>
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="views" fill="#3f51b5" />
                            </BarChart>
                        </ResponsiveContainer>
                        {/* Date Table for User Movies */}
                        <Box mt={4}>
                            <Typography variant="h6">Watch Dates and Counts per Movie</Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Audio File Name</TableCell>
                                        <TableCell>Sub Movie Id</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Watch Count</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(userWatchData).map(([movieName, data]: [any, any]) => (
                                        Object.entries(data.dates).map(([date, count]) => (
                                            <TableRow key={`${movieName}-${date}`}>
                                                <TableCell>{movieName}</TableCell>
                                                <TableCell>{data.subMovieId}</TableCell>
                                                <TableCell>{date}</TableCell>
                                                <TableCell>{String(count)}</TableCell>
                                            </TableRow>
                                        ))
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                )}
            </Box>

            <Divider sx={{ my: 4 }} />

            {/* Movie Monitoring */}
            <Box my={4}>
                <Typography variant="h5" gutterBottom>Movie Monitoring</Typography>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Select Audio File</InputLabel>
                    <Select
                        value={selectedMovie}
                        onChange={(e) => setSelectedMovie(e.target.value)}
                    >
                        {uniqueMovies.map(movie => (
                            <MenuItem key={movie} value={movie}>{movie}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {selectedMovie && (
                    <Box my={4}>
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Pie data={movieViewsChartData} dataKey="views" nameKey="userId" outerRadius={100} label>
                                    {movieViewsChartData.map((_, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                        {/* Date Table for Movie Views */}
                        <Box mt={4}>
                            <Typography variant="h6">View Dates and Counts per User</Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>User ID - SubMovie ID</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell>View Count</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(movieViewsData).map(([userKey, data]: [any, any]) => (
                                        Object.entries(data.dates).map(([date, count]) => (
                                            <TableRow key={`${userKey}-${date}`}>
                                                <TableCell>{userKey}</TableCell>
                                                <TableCell>{date}</TableCell>
                                                <TableCell>{String(count)}</TableCell>
                                            </TableRow>
                                        ))
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                )}
            </Box>

            <Divider sx={{ my: 4 }} />

            {/* Movie Views by Date Section */}
            <Box my={4}>
                <Typography variant="h5" gutterBottom>Movie Views by Date</Typography>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Select Date</InputLabel>
                    <Select
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                    >
                        {uniqueDates.map(date => (
                            <MenuItem key={date} value={date}>{date}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {selectedDate && (
                    <Box>
                        <Typography variant="h6" gutterBottom>Movies Watched on {selectedDate}</Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={dateFilteredData.map(d => ({ audioFileName: `${d.audioFileName} - ${d.subMovieId}`, views: 1 }))}>
                                <XAxis dataKey="audioFileName" />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="views" fill="#3f51b5" />
                            </BarChart>
                        </ResponsiveContainer>
                        {/* Date Table for Selected Date */}
                        <Box mt={4}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Audio File Name - SubMovie ID</TableCell>
                                        <TableCell>Views</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dateFilteredData.map(d => (
                                        <TableRow key={`${d.audioFileName}-${d.subMovieId}`}>
                                            <TableCell>{`${d.audioFileName} - ${d.subMovieId}`}</TableCell>
                                            <TableCell>1</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                )}
            </Box>

            <Divider sx={{ my: 4 }} />

            {/* Course Monitoring */}
            <Box my={4}>
                <Typography variant="h5" gutterBottom>Course Monitoring</Typography>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Select Course</InputLabel>
                    <Select
                        value={selectedCourse}
                        onChange={(e) => setSelectedCourse(e.target.value)}
                    >
                        {uniqueCourses.map(course => (
                            <MenuItem key={course} value={course}>{course}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {selectedCourse && (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Course ID</TableCell>
                                <TableCell>Watch Count</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{selectedCourse}</TableCell>
                                <TableCell>{courseViews.length}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                )}
            </Box>

            <Divider sx={{ my: 4 }} />

            {/* Overall Course Monitoring Pie Chart */}
            <Box my={4}>
                <Typography variant="h5" gutterBottom>Overall Course Monitoring</Typography>
                <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                        <Pie data={courseData} dataKey="value" nameKey="name" outerRadius={100} label>
                            {courseData.map((_, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                    </PieChart>
                </ResponsiveContainer>
            </Box>
            {/* Ranked Movies Section */}
            <Divider sx={{ my: 4 }} />
            <Box my={4}>
                <Typography variant="h5" gutterBottom>Ranked Movies by Views (Most to Least Watched)</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Movie ID + SubMovie ID</TableCell>
                            <TableCell>Views</TableCell>
                            <TableCell>Unique Users</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rankedMovies.map((movie, index) => (
                            <TableRow key={index}>
                                <TableCell>{movie.name}</TableCell>
                                <TableCell>{movie.views}</TableCell>
                                <TableCell>{movie.uniqueUsers}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </Box>
    );
};

export default MonitoringDashboard;
