import React, { useEffect, FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../store';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Box, Button, CircularProgress, Container, Typography, Alert, Paper } from '@mui/material';
import * as yup from 'yup';
import { RootState } from '../store';
import { LogInUser } from '../features/userSlice';
import { useNavigate, Link } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import logo from '../images/logo1.png';
import { Disclaimer } from '../components/authentication/DisclaimerComponent';
import { fetchAllCourses, fetchContentOfCourse, resetSelectedVideo, resetContentOfCourse, resetCourseMovieData, setSelectedVideoDetails } from '../features/coursesSlice';
import { resetCombinationData, resetQuestionsArchive } from '../features/combinationSearchSlice';
import { resetSolverData, resetQuestionsSolverArchive } from '../features/solverSearchSlice';
interface FormValues {
  email: string;
  password: string;
  role: string;
}

const initialValues: FormValues = {
  email: '',
  password: '',
  role: 'user',
};

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
  password: yup.string().min(8, 'Password should be minimum 8 char length').required('Password is required'),
});


export const Login: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { status, error, isAuthenticated, data } = useSelector((state: RootState) => state.user);
  const [disclaimerOpen, setDisclaimerOpen] = useState(false);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
  useEffect(() => {
    localStorage.clear();
    dispatch(resetSelectedVideo());
    dispatch(fetchAllCourses());
    dispatch(resetContentOfCourse());
    dispatch(resetCourseMovieData());
    dispatch(resetQuestionsArchive());
    dispatch(resetCombinationData());
    dispatch(resetSolverData());
    dispatch(resetQuestionsSolverArchive());
  }, []);
  useEffect(() => {
    if (isAuthenticated && data) {
      setDisclaimerOpen(true);
      localStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('refreshToken', data.refreshToken);
      localStorage.setItem('permissions', JSON.stringify(data.permissions));
      localStorage.setItem('menuPermissions', JSON.stringify(data.menuPermissions));
    }
  }, [isAuthenticated, data, navigate]);
  useEffect(() => {
    if (disclaimerAccepted) {
      localStorage.setItem('disclaimerAccepted', 'true');
      navigate('/home'); // Navigate to home page if disclaimer is accepted
    }
  }, [disclaimerAccepted, navigate]);

  const onSubmit = async (values: FormValues, { setSubmitting }: any) => {
    try {
      dispatch(LogInUser(values));
    } catch (err) {
      console.log(err);
      setSubmitting(false);
    }
  };
  const handleCloseDisclaimer = (accepted: boolean) => {
    setDisclaimerOpen(false);
    if (accepted) {
      setDisclaimerAccepted(true); // Set accepted to true if the user agreed
    }
  };
  const handleLoginSuccess = async (response: any) => {
    const { tokenId, profileObj } = response;
    console.log('Login Success:', profileObj);
    dispatch(LogInUser(profileObj));
    navigate('/home');
  };

  const handleLoginFailure = async (response: any) => {
    console.log('Login Failed:', response);
    // Handle login failure
  };

  return (
    <Container maxWidth="xs">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Paper elevation={3} style={{ padding: '2em', width: '100%' }}>
          <img src={logo} alt="Logo" style={{ width: '120px', marginLeft: '120px' }} />
          <Box display="flex" alignItems="center" justifyContent="center" mb={2}>


          </Box>
          {error && <Alert severity="error">{error}</Alert>}

          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ submitForm, isSubmitting }) => (
              <Form>
                <Field component={TextField} name="email" type="email" label="Email" fullWidth margin="normal" />
                <Field component={TextField} type="password" label="Password" name="password" fullWidth margin="normal" />
                <Box display="flex" justifyContent="flex-end" mt={2}>
                  {status === 'loading' ? <CircularProgress /> : <Button variant="contained" color="primary" disabled={isSubmitting} onClick={submitForm}>Login</Button>}
                </Box>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                  <Link to="/signup" style={{ textDecoration: 'none', color: 'blue' }}>Not signed up yet?</Link>
                </Box>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                  <Link to="/ForgotPassword" style={{ textDecoration: 'none', color: 'blue' }}>
                    Forgot password?
                  </Link>
                </Box>
                {disclaimerOpen && <Disclaimer open={disclaimerOpen} onCloseBoolean={handleCloseDisclaimer} />}
              </Form>
            )}
          </Formik>
          {/* 
          <GoogleLogin
            clientId="425772154086-2m7p1t40j74ieqdjj7sgd61rr4t4poqg.apps.googleusercontent.com" //todo: move it to .envs
            buttonText="Sign in with Google"
            onSuccess={handleLoginSuccess}
            onFailure={handleLoginFailure}
            cookiePolicy={'single_host_origin'}

          /> */}
        </Paper>
      </Box>
    </Container>
  );
};
